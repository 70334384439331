import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ChevronSmall as ChSmal } from './../Icons';
import { mediaBreakpointOnlyXs, mediaBreakpointDownLg, mediaBreakpointUpXl,mediaBreakpointUpLg, mediaBreakpointDownSm, mediaBreakpointOnlyMd } from "styled-bootstrap-responsive-breakpoints";
import { isOdd } from './../../utils';

const CoverWrapper = styled.div`
  font-size: 1.5em;
  text-align: center;
  color: black;
  margin:0;
  width: auto;
  ${mediaBreakpointUpXl`
    width:400px;
  `}

  margin-left: ${props=> props.isOdd ? '20px' : '0'  }

  width:100%;
  position:relative;
  overflow:hidden;
  ${mediaBreakpointDownLg`
    max-width:400px;
    width:400px;
    margin: auto;
 `}

 @media screen and ( min-width: 1400px ){
     max-width:476px;
     width:476px;
     margin: auto;     
 }
 

 


  ${mediaBreakpointOnlyMd`
    max-width:300px;
    width:300px;
    margin: auto;

    
    `}
    
    @media screen  and ( min-width: 768px )  and ( max-width: 1024px ){
        margin-left: ${props=> props.isOdd ? '20px' : 'auto'  }
        margin-right: ${props=> !props.isOdd ? '20px' : 'auto'  }
    }
  ${mediaBreakpointDownSm`
    margin:auto;
    width: auto;
    `}
}
`;

const CoverContent = styled.div`
    padding-top:1.6875rem;
    padding-bottom:60px;
    ${mediaBreakpointDownSm`
        padding-bottom:55px;
        ${({ isLast }) => isLast && `
            margin-left: 20px;
            padding-bottom:150px;            
        `}
    `}
    
`;

const CoverTitle = styled.h3`
    position:relative;
    font-family: ${props=> props.theme.font.medium};
    letter-spacing: 0.075em;
    font-size: 1.500rem;
    line-height: 30px;
    color: black;
    padding:0em 50px;   
    ${mediaBreakpointDownSm`
        font-size: 1rem;
        line-height: 1.875rem;
        letter-spacing: 0.075rem;
    `}

    ${mediaBreakpointDownLg`
        padding:0em 0px;   
    `}

    ${mediaBreakpointDownLg`
        font-size: 1.300rem;
    `}




    
    &:after{
        content: "";
        width: 27px;
        position: absolute;
        background: black;
        height: 2px;
        bottom: -16px;
        left: 0;
        right: 0;
        margin: auto;
    }
    margin-bottom: 1rem;

`;

const CoverLink = styled(Link)`
    font-family: ${props=>props.theme.font.medium};
    text-decoration:none;
    color:${ props=>props.theme.colors.black.regular};
    font-size:14px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    margin-top:0.875rem;
    letter-spacing: 0.1rem;
    :hover{
        text-decoration:underline;
        color:initial;
    }

    @media screen and (max-width: 767px){
       font-size:12px;
    }
`;

const CoverImgWrapper = styled.div`
    overflow:hidden;
`;

const CoverImg = styled.div`
    width:100%;
    min-height: 400px;
    background-image: url('${ props => props.imgSrc }');
    background-size: cover;
    background-color: ${ props => props.theme.colors.gray.light };
    background-repeat: no-repeat;
    transition: transform .5s;
    background-position:center;
    ${CoverWrapper}:hover & {
        transform: scale(1.25);
        transition: transform .5s;
    }

    ${mediaBreakpointOnlyMd`
        min-height: 300px;
        height: 300px;
        background-size: cover;
    `}


    @media screen and (min-width: 1400px){
        min-height: 455px;
    }



    ${mediaBreakpointOnlyXs`
        min-height: 63vw;
    `}


`;

const ChevronSmall = styled(ChSmal)`
    width:7px;
`;


const ArticleCover = (props) =>{
    let article = props.article;
    let articleKey  = props.articleKey;
    return (
        <CoverWrapper className={"cover-wrapper"} isOdd={ isOdd( articleKey ) }>
            <CoverImgWrapper>
                <CoverImg className={"cover-image"} imgSrc={article.featuredmedia} />
            </CoverImgWrapper>                  
            <CoverContent>
                <CoverTitle>{article.title.rendered}</CoverTitle>
                <CoverLink  to={{pathname:`/article/${article.slug}`}}>READ ARTICLE  <ChevronSmall style={{marginLeft: '9px'}} fill={'#000'} height={"10px"}/></CoverLink>
            </CoverContent>
        </CoverWrapper>
    )
}

export default ArticleCover;