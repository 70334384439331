import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { connect } from 'react-redux';
import styled from 'styled-components'
import { Container, Row, Col } from '@bootstrap-styled/v4';
import underlined from './../../styles/mixings.js';
import { ChevronSmall as ChSmal } from './../Icons';
import { openModal, closeModal } from './../../actions/Modal';
import { mediaBreakpointDownXs, mediaBreakpointDownMd, mediaBreakpointDownLg } from "styled-bootstrap-responsive-breakpoints";

import TweenLite from 'gsap';
import scrollTo from './../../../node_modules/gsap/ScrollToPlugin';

const HeroWrapper = styled.div`
display:flex;
align-items:center;
height:100%;
background-color: ${props=>props.theme.colors.blue.regular}
background-image:url('${props=>props.backgroundUrl}');
background-repeat: no-repeat;
background-size: ${props => props.backgroundSize};
background-position: 50%;
text-align:center;
position:relative;
height:36.25em;
margin-top: 90px;
${mediaBreakpointDownXs`
   background-size: cover;
   height:326px;
   padding: 1em 0;
`}
${mediaBreakpointDownMd`
    margin-top: 87.5px;
`}
${mediaBreakpointDownLg`
    margin-top: 87.5px;
`}
&:before{
    content:"";
    position:absolute;
    opacity: .65;
    background-color: ${props=>props.theme.colors.black.regular}
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
`;
  
const HeroContent = styled(Container)`
position:relative;
z-index:2;
@media screen and (min-width: 576px),
@media screen and (min-width: 768px),
@media screen and (min-width: 992px){
    max-width:900px;
}

@media screen and (min-width: 1400px){
    max-width:921px;
}
`;

const P = styled.p`
    font-family: ${props=>props.theme.font.bolder}
    color:${props=>props.theme.colors.white.regular};
    font-size: 2.313em;
    letter-spacing: 1.2px;
    line-height: 3.563rem;
    margin-bottom:1.3rem;
    ${underlined({color:'white', left:'48%', bottom:'-13px'})}
    
    @media screen and ( min-width: 767px ) and ( max-width: 1024px ){
        font-size: 30px;
    }

    ${mediaBreakpointDownXs`
        font-size: 5.4vw;    
        line-height: 2.063rem;
        margin-top: 40px;
        margin-bottom: 1.4rem;
        `}
`;

const Button = styled.button`
    font-family: ${props=>props.theme.font.bolder}
    font-size:0.875em;
    line-height:0.875em;
    color: ${props=> props.theme.colors.white.regular};
    letter-spacing:.2em;
    display: inline-flex;
    align-items: center;
    background:transparent;
    border:none;
    &:hover{
        text-decoration:underline;
        color: ${props=> props.theme.colors.white.regular};
    }
    ${mediaBreakpointDownXs`
        margin-bottom: 60px;
        font-size:10px;
    `}
`;


const ArticleLink = styled(Link)`

    font-family: ${props=>props.theme.font.bolder}
    font-size:0.875em;
    line-height:0.875em;
    color: ${props=> props.theme.colors.white.regular};
    letter-spacing:.2em;
    display: inline-flex;
    align-items: center;
    background:transparent;
    border:none;
    &:hover{
        text-decoration:none;
        color: ${props=> props.theme.colors.white.regular};
    }
    ${mediaBreakpointDownXs`
        margin-bottom: 60px;
        font-size:10px;
    `}
`;

const ChevronSmall = styled(ChSmal)`
    margin-left:0.750rem;
    width:7px;
`;

const DynamicRow = styled(Row)`

    // Added margin auto to the first col in the row
    > div {
        @media screen and ( max-width: 1024px ){
            margin:auto;
        }
    }
`;

class Hero extends Component {

    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (link)=>{

        // Link to post if there are a user logged
        if( Object.getOwnPropertyNames(this.props.user).length > 0  ){
            window.open(link,"_self");
        }else{

            if(this.props.isMobile){
                TweenLite.to(window, .4,  {scrollTo: {y:0, autoKill:false}, onComplete: ()=> {  this.props.openModal(true) } })
            }else{
                this.props.openModal(true)
            }
        }

        
    }


    render() {

        let { user, articles } = this.props;
        let article = articles[0];

        let userLogged = Object.getOwnPropertyNames(user).length > 0;
        let HeroData = {
            paragraph: "As the market is continuously shifting with persistent challenges and possibilities, we leverage insights and perspectives on key topics to power future brand growth.",
            buttonText:"SIGN UP TO GET ACCESS",
            background: "./images/hero-bg.png"
        }
        if( userLogged  ){

           
            console.log('article:', article)
            HeroData = {
                paragraph: article.title.rendered,
                buttonText:"READ ARTICLE",
                background: this.props.isMobile ? article.featuredmedia : article.feature_cover,
                link: `article/${article.slug}`
            }
        }

        console.log('articles: from hero', articles[0])
        return (
            <React.Fragment>
            {
                <HeroWrapper  backgroundUrl={HeroData.background} backgroundSize={ userLogged ? "cover" : "contain" } >
                    <HeroContent>
                        <DynamicRow noGutters={true}>
                            <Col xs={{size:10}} lg={{size:12}}>
                                <P>
                                    {HeroData.paragraph}
                                </P>
                                {
                                    userLogged ? 
                                        <ArticleLink to={{pathname:`article/${article.slug}`}}>Read Article <ChevronSmall style={{marginLeft: '9px'}} fill={'#fff'} height={"10px"}/></ArticleLink> 
                                    : <Button  tabindex="0" onClick={()=> this.handleClick(HeroData.link) }>{HeroData.buttonText} <ChevronSmall height={"20px"}/></Button>
                                }
                            </Col> 
                        </DynamicRow>
                    </HeroContent>
                </HeroWrapper>
            }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    modalIsOpen: state.modalIsOpen,
    articles: state.articles
  });
  
  const mapDispatchToProps = (dispatch) => ({
    openModal: (isOpen)=>dispatch( openModal(isOpen) ),
    closeModal: (isOpen) => dispatch( closeModal(isOpen) )
  });

export default connect(mapStateToProps, mapDispatchToProps)(Hero);

