import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";

const InputStyled = styled.input`
	border:1px solid ${props=> props.error ? props.theme.colors.red.regular : props.theme.colors.gray.ligter_7d};
	border-radius:5px;
	padding: 30px 12px 20px;
	height:54px;
    width:100%;
    background:transparent;
    color:${props=> props.value ? props.theme.colors.black.regular : props.theme.colors.gray.light };
    font-family:${props=> props.theme.font.medium }
    position:relative;
    z-index:2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    

  
`;

const Label = styled.label`
    display: inline-block;
    position:absolute;
    left: 13px;
    top: 16px;
    transition: all 150ms ease-in;
    color: #676767;
    font-size: 1rem;
    z-index: 3;


    
    ${props=> props.active ? `
        transform: translateY(-.7rem);
        font-size: 0.75rem;        
        color: #000;
    `:''}
`;

class Input extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            focused: false
        }

        this.handleBlur   = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus  = this.handleFocus.bind(this);

    }

    handleBlur = (event)=>{
        this.setState({ focused: false });
        this.props.field.onBlur(event);
    }

    handleFocus = (event) => {
        this.setState({ focused: true });
    }

    handleChange = (e) =>{
        let {
            field: { name },
            form: { setFieldValue },
        } = this.props
        console.log('this.props:', this.props)
        // Prevent letters and other characters on number fields [ Since Firefox has some issues with input number we create this functionality to hack that error ]
        if( name === 'phoneNumber' || name === 'zipCode'  ){
            const regex = /^[0-9\b]+$/;
            if (e.target.value === '' || regex.test(e.target.value)) {
               setFieldValue(name, e.target.value);
            }else{
                e.preventDefault()
            }
        }else{
            setFieldValue(name, e.target.value);
        }
    }

    render(){

        let {
            field: { name, value},
            form: { errors },
            id,
            label
        } = this.props

        let fieldError = errors ? errors[name] : false;
        return(
            <React.Fragment>
                <div className="field-group">
                    <Label active={value || this.state.focused } htmlFor={name}>{label}</Label>
                    <InputStyled 
                    error={fieldError}
                    name={name}
                    id={id}
                    type={'text'}
                    value={value}
                    onChange={ this.handleChange }
                    onFocus={ (event) => this.handleFocus(event)   }
                    onBlur={ (event)  => this.handleBlur(event)}
                    />
                </div>
            </React.Fragment>            
        )
        
    }
}

export default Input
