import {  MENU_MOBILE_IS_OPEN } from './types';


export function menuMobileIsOpen(bool) {
    return {
        type: MENU_MOBILE_IS_OPEN,
        menuIsOpen: bool
    }
}



export function openMenu(  ) {
    return (dispatch) => {
        dispatch( menuMobileIsOpen(true) )
    };
}

export function closeMenu(  ) {
    return (dispatch) => {
        dispatch( menuMobileIsOpen(false) )
    };
}
  
  