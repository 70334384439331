import React, { Component } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import BootstrapProvider from '@bootstrap-styled/provider';
import Theme from '../../styles/theme.js';
import styled from 'styled-components';
import SignUpModal from '../SignUpModal';
import MenuMobileModal from '../MenuMobileModal';
import { connect } from 'react-redux';
import { getDynamicContentFromWP } from './../../actions/General';



const MainWrapper = styled.div`
    max-width:1200px;
    margin:auto;
    background: ${props=>props.theme.colors.white.regular}

    @media screen and  ( min-width: 1200px  ){
        max-width:1400px
    }

    @media screen and  ( min-width: 1400px  ){
        max-width:100%
    }
`;




class LayoutContainer extends Component {

    constructor(props){
        super(props)
        this.state = {
          isMobile : false
        };
      }

    componentDidMount() {
        this.props.getDynamicContentFromWP(`${process.env.REACT_APP_WP_API_URL}/wp-json/mccann/v1/general-data`);    
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    
    resize() {
        this.setState({isMobile: window.innerWidth <= 760});
    }


    componentWillUnmount() {
        window.removeEventListener('resize',this.resize.bind(this));
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, { generaldata: this.props.generaldata, isMobile: this.state.isMobile })
          );      

        let interests = this.props.generaldata.signUpForm && this.props.generaldata.signUpForm ? this.props.generaldata.signUpForm : {};
        return (
            <BootstrapProvider theme={Theme}>
                    <MainWrapper>
                        <Header isMobile={this.state.isMobile} locationPath={this.props.locationPath} />
                            {childrenWithProps}
                        <Footer />
                        <SignUpModal interests={interests} />
                        <MenuMobileModal />
                    </MainWrapper>
            </BootstrapProvider>
        );
    }
}


const mapStateToProps = (state) => ({
    generaldata: state.generaldata
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getDynamicContentFromWP: (url) => dispatch(getDynamicContentFromWP(url))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);