import { GENERAL_DATA_FETCH_DATA_REQUEST, GENERAL_DATA_FETCH_DATA_SUCCESS, GENERAL_DATA_FETCH_DATA_FAILL } from './types';

export function dataRequested(bool) {
    return {
        type: GENERAL_DATA_FETCH_DATA_REQUEST,
        loading: bool
    }
}

export function dataRequestedSuccess(data) {
    return {
        type: GENERAL_DATA_FETCH_DATA_SUCCESS,
        generaldata: data
    }
}

export function dataRequestedFaill() {
    return {
        type: GENERAL_DATA_FETCH_DATA_FAILL,
        generaldata: null
    }
}


export function getDynamicContentFromWP(url) {
return (dispatch) => {
    dispatch(dataRequested(true));
    fetch(url)
    .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch(dataRequested(false));
        return response;
    })
    .then((response) => response.json())
    .then((data) => dispatch(dataRequestedSuccess(data)))
    .catch(() => dispatch(dataRequestedFaill()));
};
}