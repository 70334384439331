import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import UserLogin from './UserLogin';
import UserWelcome from './UserWelcome';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { mediaBreakpointDownLg, mediaBreakpointUpMd, mediaBreakpointUpLg, mediaBreakpointDownMd, mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";
import { CloseIcon, HamburgerIcon } from './../Icons';
import { openModal, closeModal } from './../../actions/Modal';
import { openMenu, closeMenu } from './../../actions/Menu';
import TweenLite from 'gsap';
import scrollTo from './../../../node_modules/gsap/ScrollToPlugin';

const DesktopHeader = styled.div `
    ${mediaBreakpointDownLg`
        display:none;
    `}
    background:${props=>props.theme.colors.black.regular}
    width:1075px;
    max-width:1075px;
    margin:auto;

    @media screen and ( min-width:1400px){
        width:1250px;
        max-width:1250px;
        margin:auto;
    }
`;

const MobileTabletHeader = styled.div `
    background:${props=>props.theme.colors.black.regular}
    min-height: 90px;
    height:90px;
    ${mediaBreakpointUpMd`
        display:none;
    `}

    ${mediaBreakpointDownXs`
        min-height: 50px;
        height:50px;
    `}



    ${mediaBreakpointDownLg`
        display:block;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 9;
    `}

    


    .login-welcome-row{
        height:70px;
        ${mediaBreakpointDownMd`
            height: 70px;
            line-height:70px;
        `}
        ${mediaBreakpointDownXs`
            height: 38px;
            padding: 0;
        `}
    }
`;

const Top = styled.div `
    background:${props=>props.theme.colors.black.regular}
`;

const Bottom = styled.div `
    background: ${props=>props.theme.colors.gray.light}
`;

const HeaderContainer = styled.div `
    ${mediaBreakpointDownLg`
        display:none;
    `}
    font-size:16px;
    font-size: 1rem;
    background:${props => props.theme.colors.black.regular};
    color:${props => props.theme.colors.white.regular};
    min-height: 90px;
    height:90px;
    display:flex;
    align-items:center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    ${mediaBreakpointDownLg`
        background:${props => props.theme.colors.gray.light};
        min-height: 90px;
        height:90px;
    `}

    a{
        color: inherit;
        :hover{
            text-decoration: none
        }
    }
`

const Logo = styled.h1 `
    background:url('http://mccannworldgroup.com/images/logo.svg')center -100px no-repeat;
    position: relative;
    display: inline-block;
    vertical-align: center;
    background-size: contain;
    animation: logo-in 1s ease 1s forwards;    
    width: 33px;
    height: 30px;
    overflow: visible;
    cursor:pointer;

    @media screen and ( min-width: 1200px ){
        height: 50px;
        :hover {
            animation: logo-out 1s ease 0s;
        }
        :hover span {
            animation: logoInit-in 1s ease 0s;
        }
    }

   
 
    a{
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
    `;


const InitialLogo = styled.span `
    color:transparent;
    background:url('http://mccannworldgroup.com/images/logo-initial.svg') center center no-repeat;
    background-size: contain;
    width: 90px;
    left: -30px;
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    animation:logoInit-out 1s ease 1s forwards;
`;

const CloseArticleBtn = styled(Link)
`
    color:${props => props.theme.colors.white.regular};
    &:hover{
        text-decoration:none;
        color:inherit;
    }

    @media screen and ( max-width: 768px ){
        > svg {
            width:20px;
        }
    }

`;

const CloseModalBtn = styled.div `
    color:${props => props.theme.colors.white.regular};
    @media screen and ( max-width: 1200px ){
        > svg {
            width:12px;
        }
    }
`;

const Slogan = styled.h2 `
    font-size:1em;
    letter-spacing: 0.2em;

    font-family: ${props => props.theme.font.bolder}
    color: ${props => props.mobile ? props.theme.colors.white.regular : 'inherit'}
    ${props=> props.mobile && `
        font-size: 2.8vw;
        position: relative;
        word-spacing: 60px;
        line-height: 40px;
        text-align: center; 

        > div {
            position: absolute;
            left: -1.7em;
            right: 0;
            margin: auto;
            min-width:unset;
            width: 7.5vw;
        }`}
    ${props=> props.menuOpen && `
        color:black;
    `}
`;

const HamburguerDiv = styled.div `
    position: absolute;
    left: 0;
    top: 0;
    width: 55px;
    height: 50px;
    text-align: center;

    @media( min-width: 768px ){
        height: 90px;
    }
`


const CloseBtnDiv = styled.div `
    position: absolute;
    right: 0;
    top: 0;
    width: 55px;
    height: 50px;
    @media( min-width: 768px ){
        height: 90px;
    }
`

const MobileTabletHeaderContainer = styled.div `
    margin: 0 auto;
    position: relative;

    max-width: 480px
    height :50px;
    min-height: 50px;
    line-height: 50px;

    @media only screen and (min-width: 768px) {
        max-width:767px
        min-height: 90px;
        line-height: 90px;
        height:90px;
    }

    @media only screen and (min-width: 1200px) {
        max-width:1000px
    }

    @media only screen and (min-width: 1400px) {
        max-width:1160px
    }
`;

const HeaderWrapperDiv = styled.div `
    width: 100%;
    height: 100%;
    display: table;
`
const LogoWrapperDiv = styled.div `
    width: 100%;
    height: 100%;
`

const LogoWithSlogan = styled.div `
    font-size:1em;
    letter-spacing: 0.2em;
    font-family: ${props => props.theme.font.bolder}
    color: ${props => props.mobile ? props.theme.colors.white.regular : 'inherit'}

    position: relative;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    line-height: 0;
    text-align: center;

    @media( min-width: 768px ){
        font-size: 1rem
    }

    > h1 {
        width: 55px;
        height: 32px;

        @media screen and (min-width: 768px){
            width: 33px;
            height: 51px;
        }
    }

    ${props=> props.mobile && `
        font-size: 0.6875rem;
    `}
    
    ${props=> props.menuOpen && `
        color:black;
    `}
    span{
        position: absolute;
        top: 50%;    
    }

    span:first-of-type{
        transform: translateY(-50%);
        right: calc(50% + 30px);
    }

    span:last-of-type{
        transform: translateY(-50%);
        left: calc(50% + 34px);
    }
`;



class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMobile: false
        }

        this.openModalHandler = this.openModalHandler.bind(this);
        this.handleHomeLink = this.handleHomeLink.bind(this);
    }

    openModalHandler = () => {
        if (this.props.isMobile) {
            TweenLite.to(window, .4, { scrollTo: { y: 0, autoKill: false }, onComplete: () => { this.props.openModal(true) } })
        } else {
            this.props.openModal(true)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        const { locationPath } = this.props;
    }

    resize() {
        this.setState({ isMobile: window.innerWidth <= 575 });
    }

    handleHomeLink(e) {
        if (this.props.locationPath === undefined) {
            e.preventDefault();
        }
    }

    goToGlobalSite() {
        window.open(
            'https://www.mccannworldgroup.com/',
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    render() {
        let page = (this.props.locationPath && this.props.locationPath.indexOf('article/') ? 'article' : '');
        let user = this.props.user;
        let dynamicDesktopMenuElement;
        let dynamicNoDesktopMenuElement;
        let rowNoDesktop;


        if (page === 'article') {
            dynamicDesktopMenuElement = <CloseArticleBtn to="/"><CloseIcon stroke="#fefc09" /></CloseArticleBtn>
            dynamicNoDesktopMenuElement = <CloseArticleBtn to="/"><CloseIcon stroke="#fefc09" /></CloseArticleBtn>
        } else if (Object.getOwnPropertyNames(user).length !== 0) {
            dynamicDesktopMenuElement = <UserWelcome user={user} />;
            rowNoDesktop = <UserWelcome user={user} />;
        } else {
            dynamicDesktopMenuElement = <UserLogin  openModalHandler={this.props.openModal}/>;
            rowNoDesktop = <UserLogin  openModalHandler={ this.openModalHandler }/>;
        }

        if (this.props.modalIsOpen && this.state.isMobile) {
            dynamicNoDesktopMenuElement = <CloseModalBtn onClick={this.props.closeModal} ><CloseIcon  height="25px" width="25px" strokeWidth="3px" stroke="#fefc09" /></CloseModalBtn>
        }

        if (this.props.menuIsOpen) {
            dynamicNoDesktopMenuElement = <CloseModalBtn onClick={this.props.closeMenu} ><CloseIcon  height="25px" width="25px" strokeWidth="3px" stroke="#fefc09" /></CloseModalBtn>
        }
        return (
            <React.Fragment>
                <HeaderContainer>
                        <DesktopHeader>
                            <Row className="align-items-center">
                                <Col className="d-none d-sm-block" >
                                <Link to="/" onClick={this.handleHomeLink} ><Slogan>THOUGHT LEADERSHIP</Slogan></Link>
                                </Col>
                                <Col className="text-center d-none d-sm-block">
                                    <Logo>
                                        <a href="https://www.mccannworldgroup.com/" target="_blank">
                                            <InitialLogo>McCann Worldgroup</InitialLogo>
                                        </a>
                                    </Logo>
                                </Col>
                                <Col className="text-right">
                                    {
                                        dynamicDesktopMenuElement
                                    }
                                </Col>
                            </Row>
                        </DesktopHeader>
                </HeaderContainer>
                <MobileTabletHeader>
                    <Top>
                        <MobileTabletHeaderContainer>
                            <HeaderWrapperDiv>
                                <LogoWithSlogan  menuOpen={this.props.menuIsOpen} mobile={true}>
                                    <span>THOUGHT</span>
                                    <Logo onClick={this.goToGlobalSite} />
                                    <span>LEADERSHIP</span>
                                </LogoWithSlogan>
                                <HamburguerDiv>
                                    {
                                      <HamburgerIcon style={{ visibility: this.props.menuIsOpen  || this.props.modalIsOpen ||  page == 'article'  ? 'hidden' : 'initial' }} handleClick={this.props.openMenu} /> 
                                    }
                                </HamburguerDiv>
                                <CloseBtnDiv>
                                    {
                                        dynamicNoDesktopMenuElement
                                    }
                                </CloseBtnDiv>
                            </HeaderWrapperDiv>
                        </MobileTabletHeaderContainer>
                    </Top>
                    { page !== 'article' &&
                    <Bottom>
                        <Container>
                            <Row className="login-welcome-row" >
                                <Col sm={{size:12}} xs={{size:12}} >
                                    {
                                        rowNoDesktop
                                    }
                                </Col>
                            </Row>
                        </Container>                       
                    </Bottom>}
                </MobileTabletHeader>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    modalIsOpen: state.modalIsOpen,
    menuIsOpen: state.menuIsOpen
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (isOpen) => dispatch(openModal(isOpen)),
    closeModal: (isOpen) => dispatch(closeModal(isOpen)),
    openMenu: (isOpen) => dispatch(openMenu(isOpen)),
    closeMenu: (isOpen) => dispatch(closeMenu(isOpen))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);