import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import Checkbox from './Fields/Checkbox';
import Input from './Fields/Input';
import {Helmet} from "react-helmet";
import MultiSelectChecboxes from './Fields/MultiSelectChecboxes';

import { connect } from 'react-redux';
import { signUp } from './../../../actions/User';
import { LoadingIcon } from './../../Icons';
import { mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";


const TitleContainer = styled.div `
	margin-bottom:2.1875rem;
	font-size:1.1875rem;
	font-family: ${props=> props.theme.font.medium}
	h5 {
		line-height:1.875rem;
	}
	h4, h5{
		letter-spacing: 0.95px;
	}
	h1{
		font-family: ${props=> props.theme.font.medium}
		letter-spacing: 0.95px;
		color: #000000;
	}
`;


const Form = styled.form `
	max-width: 427px;
    margin: 0 auto;

	div[class*="MenuList"] {
	    max-height: none !important;
	}

	-webkit-overflow-scrolling: touch;

	.row > .col-12,
	.row > [class*="col-"] {
		padding-right: 2px;
		padding-left: 2px;
		margin-bottom:12px;
		
		${mediaBreakpointDownXs`
			margin-bottom:25px;
			padding-right: 12px;
			padding-left: 12px;
		`}

	}

	.row:first-of-type > .col-12:first-of-type {
		padding-right: 6px;
		${mediaBreakpointDownXs`
			padding-right: 12px;
		`}
	}

	.row:first-of-type > .col-12:last-of-type {
		padding-left: 6px;
		${mediaBreakpointDownXs`
			padding-left: 12px;
		`}
	}

	.field-group label {
		pointer-events: none;
	}

`;

const Fieldset = styled.fieldset `
	position:relative;
`;

let ErrorMessage = styled.span `
    display: block;
	font-size: 14px;
	color: ${props=>props.theme.colors.red.regular}
`;

const Label = styled.label `
	  font-size: 14px;
	  vertical-align: bottom;
	  display: inline;
	  
	  ${mediaBreakpointDownXs`
	    margin-left: 2vw;
	  `}
	  @media screen and (max-width:410px){
	    font-size: 3.7vw;
	  }
	  span{
	  	text-decoration: underline;
		cursor:pointer;
		vertical-align: bottom;
	  }
`;


const Submit = styled.button `
	background: ${props=>props.theme.colors.black.regular};
	color:${props=>props.theme.colors.white.regular};
	font-family: ${props=>props.theme.font.bolder};
	font-size:1rem;
	text-transform:uppercase;
	border:none;
	padding: 0.875rem 2.5rem 0.75rem 2.5rem;
	position:relative;
	margin-top:10px;
	letter-spacing: 0.1rem;
	margin-left: 2px;
	${mediaBreakpointDownXs`
		margin-left: 12px;
	`}
`;

const BackBtn = styled.button `
	background: ${props=>props.theme.colors.black.regular};
	color:${props=>props.theme.colors.white.regular};
	font-family: ${props=>props.theme.font.bolder};
	font-size:1rem;
	text-transform:uppercase;
	border:none;
	padding: 0.875rem 2.5rem 0.75rem 2.5rem;
	position:relative;
	margin-top:10px;
	letter-spacing: 0.1rem;
`;

const Icon = styled.i `
	position: absolute;
	top: 16px;
	left: 10px;
	> svg {
		height: 20px;
	}
`;

const Button = styled.button `
	background: ${props=>props.theme.colors.black.regular};
	color:${props=>props.theme.colors.white.regular};
	font-family: ${props=>props.theme.font.bolder};
	font-size:1rem;
	text-transform:uppercase;
	border:none;
	padding: 1.125rem 2.5rem	
`;


let SignUpSuccess = styled.div `
	p{
		font-family: ${props=>props.theme.font.medium}
		font-size:0.875rem;
		line-height:1.3125rem;
		margin-bottom:1.5625rem;
		&:first-child{
			line-height:1.75rem;
			font-size:1.1875rem;
			strong{
				font-family: ${props=>props.theme.font.bolder}
			}
		}
	}
`;

let OverflowContent = styled.div `
  height: calc(100vh - 225px);
  max-height: 560px;
  overflow-y: scroll;
  width: 110%;
  padding-right: 10%;
  font-size: 14px;
  color: #000000;
  p, h2{
  	line-height: 21px;
  	color: #000000;
  }
  h2{
	  font-size: 1
  }
  ::-webkit-scrollbar-track {
    background-color: #eeeeee;
    border-radius: 10px;
  }
  ::-webkit-scrollbar{
	width: 10px;
	background-color: #F5F5F5;
   }
   ::-webkit-scrollbar-thumb
   {
    background-color: black;
    }
	${mediaBreakpointDownXs`
  		height: calc(100vh - 244px);
  	`}
	
	.m-top{
		margin-top: 25px;
	}


  `



const LegalText = (props) => {
    return (
        <React.Fragment>
    		<Helmet>
            	<title>Thought Leadership: Privacy Policy</title>
    			<meta name="description" content="Description"/>
    		</Helmet>
			<Row>
				<Col xs={{size:12}}  lg={{size:12}} >					
					<TitleContainer>
						<h1>Privacy Policy</h1>
					</TitleContainer>
				</Col>
				<Col  xs={{size:12}}  lg={{size:12}} >
					<OverflowContent>
							<p>Date poster: July, 2019</p>
							<p className="m-top">McCann Worldgroup (MW or we) understand concerns about privacy and work hard to comply with relevant data protection laws.<br/></p>
							<p className="m-top">This website privacy notice is provided to explain:</p>
							<p>
								•	the types of personal information we collect about individuals on our website,<br/>
								•	how we may use and share that information,<br/>
								•	the choices available to you regarding our use of your personal information,<br/>
								•	the measures we take to safeguard your personal information,<br/>
								•	how long we retain your personal information, and<br/>
								•	how to contact us about our privacy practices and exercise your data privacy rights.
							</p>
							<h2 className="m-top">
							    INFORMATION WE OBTAIN
							</h2>
							<p>
							    We may collect and store personal information that you provide to us
							    through your use of our website, including the following types of
							    information:-
							</p>
							<p>
							    · Contact information – such as your name and e-mail address,
							</p>
							<p>
							    · Employment information – such as your company, job title, and location,
							    and
							</p>
							<p>
							    · Other personal information you provide to us.
							</p>
							<p className="m-top">
							    <strong><em>Automated Collection of Data</em></strong>
							</p>
							<p>
							    When you use our website, certain personal information may be obtained by
							    automated means, such as browser cookies, web beacons, device identifiers,
							    server logs, and other technologies.
							</p>
							<p className="m-top">
							    <strong><em>What are cookies and web beacons?</em></strong>
							</p>
							<p>
							    “Cookies” are computer text files that websites send to a visitor’s
							    computer or other device to:
							</p>
							<p>
							    · uniquely identify that visitor’s browser or
							</p>
							<p>
							    · store information or settings in that visitor’s browser.
							</p>
							<p>
							    “Web beacons” – also known as internet tags, pixel tags or clear GIFs –
							    link web pages to web servers and their cookies, and may be used to
							    transmit information collected through cookies back to a web server.
							</p>
							<p className="m-top">
							    <strong>
							        <em>What kind of personal data are collected using “cookies”?</em>
							    </strong>
							</p>
							<p>
							    The information we collect using cookies may include your device IP
							    address, domain name, identifiers associated with your devices, device and
							    operating system type and characteristics, web browser characteristics,
							    language preferences, clickstream data, your interactions with our website
							    (such as the web pages you visit, links you click, videos you watch and
							    features you use), the pages that led or referred you to our website, dates
							    and times of access to our website, geolocation information, and other
							    information about your use of our website.
							</p>
							<p className="m-top">
							    <strong>
							        <em>Why do we use cookies and web beacons, and what do they do?</em>
							    </strong>
							</p>
							<p>
							    We may use cookies and web beacons to help us with any of the following:
							</p>
							<p>
							    · remember your personal information so you will not have to re-enter it,
							</p>
							<p>
							    · track and understand how you use and interact with our website,
							</p>
							<p>
							    · tailor our website around your preferences,
							</p>
							<p>
							    · measure the usability of our website,
							</p>
							<p>
							    · provide you with interest-based advertising, and
							</p>
							<p>
							    · manage and enhance the functionality of our website.
							</p>
							<p className="m-top">
							    <strong><em>Cookies – Your Choices</em></strong>
							</p>
							<p>
							    To the extent required by applicable law, we will obtain your consent
							    before placing cookies or similar technologies on your computer. You may <a href="https://www.mccannworldgroup.com/" title="Cookie Settings">adjust your cookie settings at any time by clicking here</a>. Additionally, you can stop certain types of cookies from being downloaded
							    on to your computer by selecting the appropriate settings on your web
							    browser. Most web browsers will tell you how to stop accepting new browser
							    cookies, how to be notified when you receive a new browser cookie and how
							    to disable existing cookies. You can find out how to do this for your
							    particular browser by clicking “help” on your browser’s menu or by visiting <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org</a>.
							</p>
							<p>
							    The Site is not designed to respond to “do not track” signals received from
							    browsers OR Our website may not be designed to respond to “do not track”
							    signals received from browsers. Please note that, without cookies or other
							    automated tools, you may not be able to use all of the features of our
							    website.
							</p>
							<p>
							    For more details on the cookies we use please see the <strong><a href="https://www.mccannworldgroup.com/">Cookie Notice</a>
							    </strong> below.
							</p>
							<p className="m-top">
							    <strong><em>Third-Party Web Analytics Services</em></strong>
							</p>
							<p>
							    We may obtain personal information about your online activities over time
							    and across third-party websites, devices and other online services. In
							    addition, our website may use third-party online analytics services, such
							    as those of Google Analytics and Hubspot. The service providers that
							    administer these analytics services use automated technologies to collect
							    data (such as email addresses, IP addresses, cookies and other device
							    identifiers) to evaluate, for example, use of our website and to diagnose
							    technical issues. To learn more about Google Analytics, please visit <a href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a>. You may opt out of Google Analytics by visiting <a href="https://tools.google.com/dlpage/gaoptout?hl+en=GB">https://tools.google.com/dlpage/gaoptout?hl+en=GB</a>. To learn more about Hubspot, please visit <a href="https://legal.hubspot.com/privacy-policy">https://legal.hubspot.com/privacy-policy</a>.
							</p>
							<p className="m-top">
							    <strong><em>Online Tracking and Interest-Based Advertising </em></strong>
							</p>
							<p>
							    Through our website, both we and certain third parties may collect
							    information about your online activities to provide you with advertising
							    about products and services tailored to your individual interests. Where
							    required by applicable law, we will obtain your consent for the processing
							    of your personal information for such direct marketing purposes.
							    Occasionally, you may see advertisements for our agency on other websites
							    or mobile apps – this would be when we participate in advertising networks.
							    Advertising networks allow us to broadcast advertising to internet user
							    groups that are selected based on demographic data, users’ inferred
							    interests, location and browsing context. These networks track users’
							    online activities over time by collecting information through automated
							    means, including through the use of browser cookies, web beacons, device
							    identifiers, server logs, web beacons and other similar technologies. The
							    advertising networks use this information to show advertisements that may
							    be tailored to individuals’ interests, to track users’ browsers or devices
							    across multiple websites and apps, and to build a profile of users’ online
							    browsing and app usage activities. The information our advertising networks
							    may collect includes data about users’ visits to websites and apps that
							    participate in the relevant advertising networks, such as the pages or
							    advertisements viewed and the actions taken on the websites or apps. This
							    data collection takes place on third-party websites and apps that
							    participate in the advertising networks.
							</p>
							<p>
							    To learn how to opt out of advertising network interest-based advertising
							in the European Union, please visit <a href="http://www.youronlinechoices.eu/">www.youronlinechoices.eu</a>.
							    <br/>
							    <br/>
							</p>
							<h2 className="m-top">
							    HOW WE USE PERSONAL INFORMATION
							</h2>
							<p>
							    We may use the personal information we obtain through our website to:
							</p>
							<p>
							    · personalize your experience on our website,
							</p>
							<p>
							    · provide our services to you,
							</p>
							<p>
							    · respond to your requests and inquiries,
							</p>
							<p>
							    · operate, evaluate and improve our business (including developing new
							    services, enhancing and improving our services, analyzing our services,
							    managing our communications, performing data analytics and market research,
							    and performing accounting, auditing and other internal functions),
							</p>
							<p>
							    · protect against identity theft, and prevent fraud and other criminal or
							    undesirable activity,
							</p>
							<p>
							    · market our services to you or your company, and
							</p>
							<p>
							    · comply with and enforce applicable legal requirements, relevant industry
							    standards and our policies, including this website privacy notice.
							</p>
							<p>
							    If your personal information is used in any other ways we will provide you
							    with specific notice of this at the time of collection or ensure that we
							    have your consent.
							</p>
							<p>
							    We may combine information we obtain about you – from our website or third
							    parties – for the purposes described above.
							</p>
							<p>
							    As our website may provide access to material published by online
							    publishers that are independent to us, you should also refer to the section
							    <strong> <a href="https://www.mccannworldgroup.com/">
							            Other Online Services and Third-Party Features</a>
							        ,</strong> which gives information on how your personal information may be acquired
							    and used by these publishers.
							    <br/>
							    <br/>
							</p>
							<h2 className="m-top">
							    INFORMATION SHARING
							</h2>
							<p>
							    We do not disclose personal information we obtain about you, except as
							    described in this website privacy notice.
							</p>
							<p>
							    We may share your personal information with other agencies in our network,
							    our affiliates and service providers who perform services on our behalf –
							    such as hosting our website, relationship management or data analytics.
							    This is only done for the purposes described in this website privacy
							    notice. We do not authorize our service providers to use or disclose the
							    information that we collect except as necessary to perform services on our
							    behalf or comply with legal requirements. But as stated above, as our
							    website may provide access to material published by online publishers that
							    are independent to us, you should also refer to the section
							    <strong> <a href="https://www.mccannworldgroup.com/">
							            Other Online Services and Third-Party Features
							        </a>
							        ,
							    </strong>
							    which gives information on how your personal information may be acquired
							    and used by these publishers.
							</p>
							<p>
							    We also may disclose information about you:
							</p>
							<p>
							    · if we are required to do so by law or legal process (such as a court
							    order or subpoena),
							</p>
							<p>
							    · in response to requests by government agencies, such as law enforcement
							    authorities,
							</p>
							<p>
							    · to establish, exercise or defend our legal rights,
							</p>
							<p>
							    · when we believe disclosure is necessary or appropriate to prevent
							    physical or other harm or financial loss,
							</p>
							<p>
							    · in connection with an investigation of suspected or actual illegal
							    activity,
							</p>
							<p>
							    · in the event we sell or transfer all or a portion of our business or
							    assets (including in the event of a reorganization, dissolution or
							    liquidation),
							</p>
							<p>
							    · where we have a legitimate interest in doing so and your rights as a data
							    subject are not adversely impacted, or
							</p>
							<p>
							    · otherwise with your consent.
							</p>
							<h2 className="m-top">
							    DATA TRANSFERS
							</h2>
							<p>
							    We will only share or transfer personal data to entities located outside of
							    the country in which the information was originally collected in accordance
							    with the law to ensure your rights are appropriately protected.
							    <br/>
							    <br/>
							</p>
							<h2 className="m-top">
							    YOUR RIGHTS AND CHOICES
							</h2>
							<p>
							    We offer you certain choices in connection with the personal information we
							    obtain about you. To update your preferences, update or correct your
							    information, limit the communications you receive from us, or submit a
							request, please contact us directly – see the <a href="https://www.mccannworldgroup.com/">How to Contact Us</a> section.
							    You can also unsubscribe from our marketing mailing lists by following the
							    “Unsubscribe” link in our emails.
							</p>
							<p>
							    Under EU law, all data subjects to whom the EU General Data Protection
							    Regulation (GDPR) applies have certain rights, including to:
							</p>
							<p>
							    · request access to the personal information we hold about you,
							</p>
							<p>
							    · request that we update, correct, amend, erase or restrict our use of your
							    personal information,
							</p>
							<p>
							    · exercise your right to data portability, by contacting us as indicated
							    below,
							</p>
							<p>
							    · lodge a complaint with a European supervisory authority regarding any
							    breach of European data protection rules, and
							</p>
							<p>
							    · in certain circumstances, request the erasure of or object to the
							    retention or use of personal data.
							</p>
							<p>
							    You can exercise any of your rights as a data subject under the GDPR by
							    contacting us directly –see <a href="https://www.mccannworldgroup.com/"><strong>How to Contact Us</strong>
							    </a>
							    .
							    <br/>
							    <br/>
							</p>
							<p>
							    OTHER ONLINE SERVICES AND THIRD-PARTY FEATURES
							</p>
							<p>
							    Our website may provide links to other online services and websites for
							    your convenience and information, and may include third-party features such
							    as apps, tools, widgets and plug-ins (eg., Facebook, Instagram, LinkedIn,
							    Twitter and Vimeo buttons). These services, websites, and third-party
							    features may operate independently from us. The privacy practices of these
							    third parties, including details on the information they may collect about
							    you, are subject to the privacy statements of these parties, which we
							    strongly suggest you review. To the extent any linked online services or
							    third-party features are not owned or controlled by us, we are not
							    responsible for these third parties’ personal information practices.
							    <br/>
							    <br/>
							</p>
							<p>
							    RETENTION OF PERSONAL INFORMATION
							</p>
							<p>
							    To the extent permitted by applicable law, we retain personal information
							    as long as:
							</p>
							<p>
							    · it is needed for the purposes for which we obtained it, or
							</p>
							<p>
							    · we have a separate and clear lawful basis for retaining the personal
							    data.
							</p>
							<p>
							    Our aim is always to comply with the GDPR, which necessarily means that we
							    only hold the personal data that we need and for so long as we either need
							    or can reasonably justify holding these data.
							    <br/>
							    <br/>
							</p>
							<p>
							    HOW WE PROTECT PERSONAL INFORMATION
							</p>
							<p>
							    We maintain administrative, technical and physical safeguards designed to
							    protect the personal information we obtain through our website against
							    accidental, unlawful or unauthorized destruction, loss, alteration, access,
							    disclosure or use.
							    <br/>
							    <br/>
							</p>
							<p>
							    CHILDREN’S PERSONAL INFORMATION
							</p>
							<p>
							    Our website is designed for a general audience – and primarily a business
							    audience. It is not directed at children. We do not knowingly collect or
							    solicit personal information from children – ie. individuals under the age
							    of 16 – through our website. If you believe that a child may have provided
							us with personal information, please contact us as specified in the <a href="https://www.mccannworldgroup.com/">How to Contact Us</a> section
							    of this website privacy notice.
							</p>
							<p>
							    Our website is designed for a general audience – and primarily a business
							    audience. It is not routinely directed at children. Where we wish to
							    collect or solicit personal information from children – ie., individuals
							    under the age of 16 – through our website, we will do so knowing the
							    additional requirements imposed by the GDPR and take great care to ensure
							    consent is obtained from the parent or guardian or ensure that we have
							    protected the data rights of children through some other means. In any
							    event, we will only use these data for a specific purpose, which will be
							    made clear at the time that the personal data and consent are obtained.
							    <br/>
							    <br/>
							</p>
							<h2 className="m-top">
							    UPDATES TO THIS WEBSITE PRIVACY NOTICE
							</h2>
							<p>
							    This website privacy notice may be updated periodically and without prior
							    notice to you to reflect changes in our personal information practices. We
							    will indicate at the top of the notice when it was most recently updated.
							    <br/>
							    <br/>
							</p>
							<h2 className="m-top">
							    E-RECRUITMENT THROUGH OUR WEBSITE
							</h2>
							<p className="m-top">
							    <strong>
							        <em>E-Recruitment – How we use data supplied by job applicants</em>
							    </strong>
							</p>
							<p>
							    If you apply for a position with us through our website, we may ask you to
							    provide cover letters, CVs and references that contain a range of personal
							    information relating to you including your name and contact information,
							    your education and employment history, your work skills, your desired work
							    location and work authorization status. In addition, to assist us with
							    diversity and equality monitoring, at your discretion, you may supply us
							    with gender, race, ethnicity or disability details.
							</p>
							<p>
							    This privacy notice and cookie policy, applies in full to our e-recruiting
							    activities. In addition, we may use personal information obtained from job
							    applicants’ to:
							</p>
							<p>
							    · evaluate and assess your experience and qualifications as they relate to
							    specific requirements for the position for which you applied, and possibly
							    to determine whether you would be suitable for other positions with us,
							</p>
							<p>
							    · inform you of future openings in other similar positions,
							</p>
							<p>
							    · create and manage your account, and
							</p>
							<p>
							    · respond to and communicate with you about your requests, questions and
							    comments.
							</p>
							<p className="m-top">
							    <strong>
							        <em>E-Recruitment – Sharing personal data of job applicants</em>
							    </strong>
							</p>
							<p>
							    We may share job applicants’ personal information with other agencies in
							    the Interpublic group around the world for the purpose of evaluating your
							    experience and qualifications as they relate to specific requirements for
							    the position and possibly to determine whether you would be suitable for
							    other positions within the Interpublic group. In addition, we may use your
							    personal information for reporting purposes as may be required by law.
							</p>
							<p>
							    We may also supply your personal information to third parties who perform
							    services on our behalf. For example, we may share the information you
							    provide to us with service providers such as recruitment agencies and
							    salary evaluators helping us to evaluate candidates for employment
							    opportunities, and online recruitment service providers. We will always
							    require such third parties to adequately safeguard your personal
							    information and not use it for any unauthorized purpose.
							</p>
							<p>
							    In addition, if you supply references to support your employment
							    application, we may contact referees to help us evaluate you for employment
							    opportunities with us. Doing so will necessarily involve a limited
							    disclosure of your personal information to those individuals, such as your
							    name, the fact that you have submitted an application to us for employment
							    and that you are interested in a certain type of position with us.
							</p>
							<p>
							    We will not provide your personal information to third parties for those
							    third parties’ own purposes without your prior consent.
							</p>
							<p className="m-top">
							    <strong>
							        <em>E-Recruitment – Links to other recruitment-related websites</em>
							    </strong>
							</p>
							<p>
							    Our website may provide links to other websites for your convenience and
							    information. These websites may operate independently from us. Linked sites
							    may have their own privacy notices or policies, which we strongly encourage
							    you to review to better understand their procedures for collecting, using
							    and disclosing personal information. We are not responsible for the content
							    of linked websites, any uses of these sites, or the privacy practices of
							    these sites.
							</p>
							<p className="m-top">
							    <strong>
							        <em>
							            E-Recruitment – How long we retain job applicants’ personal
							            information
							        </em>
							    </strong>
							</p>
							<p>
							    We will store the personal information that you send us via this website
							    for as long as is reasonably necessary for your employment application,
							    taking into consideration our need to answer queries or resolve problems
							    and comply with legal requirements under applicable laws. We may also
							    retain your personal information for a reasonable period to be able to
							    contact you should a suitable job opening occur in the future. If you do
							    not wish to be considered for future employment opportunities, we will
							    delete your personal information at your request. Applicants who receive
							    employment positions with us will have their information stored for longer
							    periods, consistent with employment need and local law.
							    <br/>
							    <br/>
							</p>
							<h2 className="m-top">
							    HOW TO CONTACT US
							</h2>
							<p>
							    You can contact us in relation to any privacy matter including our use of
							    your personal data or to exercise your rights by using the following
							    contact details and providing details of your request:
							</p>
							<p>
							By Email: <a href="mailto:Emily.Kapusta@Mccann.com">Emily.Kapusta@Mccann.com</a> or
							</p>
							<p>
							    By Post:
							</p>
							<p>
							    <strong>
							        Privacy Enquiries
							        <br/>
							        McCann Worldgroup
							        <br/>
							        622 Third Avenue
							        <br/>
							        New York, NY 10017
							        <br/>
							        USA
							    </strong>
							</p>
							<p>
							    Alternatively, our Data Protection Officer for the purposes of the General
							    Data Protection Regulation is:
							</p>
							<p>
							    NAME: Dr Deborah Prince
							    <br/>
							    ADDRESS: 66 Prescot Street, London E1 8 HG
							    <br/>
							EMAIL: 	<a href="mailto:gdpr.dpo@interpublic.com">gdpr.dpo@interpublic.com</a>
							    <br/>
							    <br/>
							</p>
							<h2 className="m-top">
							    COOKIE POLICY
							</h2>
							<p className="m-top">
							    <strong><em>What are cookies?</em></strong>
							</p>
							<p>
							    A “cookie” is a text file of letters and numbers that websites send to a
							    visitor’s computer or other Internet-connected device to uniquely identify
							    the visitor’s browser or to store information or settings in the browser. A
							    “web beacon,” also known as an Internet tag, pixel tag or clear GIF, links
							    web pages to web servers and their cookies and may be used to transmit
							    information collected through cookies back to a web server. We and our
							    third-party service providers may use beacons to help us track response
							    rates, identify when webpages are accessed or other types of interactions –
							    such as forwarding – and for other purposes listed below.
							</p>
							<p>
							    The term “cookies” includes cookies, tags and pixels.
							</p>
							<p>
							    By dropping a cookie on a website user’s internet device – for example,
							    their computer, laptop or smartphone – the website can recognise that
							    user’s device and store information about the user’s preferences or past
							    actions. Cookies may also allow the website to recognise the user when the
							    user returns to the website.
							</p>
							<p className="m-top">
							    <strong><em>Our Use of Cookies</em></strong>
							</p>
							<h2 className="m-top">
							    STRICTLY NECESSARY COOKIES
							</h2>
							<p>
							    These are cookies that are required for a website to function. They are
							    used to ensure the safety, security and integrity of our website, as well
							    as to enable users to move around the website and use its features. For
							    example they help support the structure of the pages that are displayed to
							    website users, help to improve navigation and allow website users to return
							    to pages they have previously visited. This type of Cookie only lasts for
							    the duration of the time website users are visiting the website. When a
							    user leaves the website they are deleted automatically.
							</p>
							<p>
							    Cookies categorized as Strictly Necessary cannot be turned off and the
							    consent of the website user is not legally required before their use – we
							    therefore do not seek your consent before using cookies that are strictly
							    necessary.
							</p>
							<p>
							    Cookies used
							</p>
							<ul>
							    <li>
							        OptanonConsent
							    </li>
							    <li>
							        OptanonAlertBoxClosed
							    </li>
							</ul>
							<table border="0" cellSpacing="0" cellPadding="0">
							    <tbody>
							        <tr>
							            <td>
							                <p align="center">
							                    Categories
							                </p>
							            </td>
							            <td>
							                <p align="center">
							                    Cookies
							                </p>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <p>
							                    cloudflare.com
							                </p>
							            </td>
							            <td>
							                <ul>
							                    <li>
							                        __cf_bm
							                    </li>
							                </ul>
							            </td>
							        </tr>
							    </tbody>
							</table>
							<h2 className="m-top">
							    FUNCTIONAL COOKIES
							</h2>
							<p>
							    These are used to recognise a website user when they return to a website.
							    This enables a website owner to personalise content for that user, for
							    example by:
							</p>
							<p>
							    1. Greeting the user by name.
							</p>
							<p>
							    2. Remembering the user’s preferences – such as choice of language – or
							    font size changes.
							</p>
							<p>
							    3. Providing users with information on local events or news by storing
							    their regional location.
							</p>
							<p>
							    4. Providing services such as watching a video or commenting on an item.
							</p>
							<p>
							    These cookies cannot track browsing activity on other websites and the
							    information they collect may be anonymised – but they will be able to
							    identify a user’s device. These cookies will remain on a user’s device
							    until the user chooses to clear them. If a user chooses to do this, they
							    will subsequently need to enter their details the next time they visit the
							    website.
							</p>
							<table border="0" cellSpacing="0" cellPadding="0">
							    <tbody>
							        <tr>
							            <td width="120">
							                <p align="center">
							                    Categories
							                </p>
							            </td>
							            <td>
							                <p align="center">
							                    Cookies
							                </p>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <p>
							                    vimeo.com
							                </p>
							            </td>
							            <td>
							                <ul>
							                    <li>
							                        vuid
							                    </li>
							                </ul>
							            </td>
							        </tr>
							    </tbody>
							</table>
							<h2 className="m-top">
							    PERFORMANCE COOKIES OR ANALYTICAL COOKIES
							</h2>
							<p>
							    These cookies are used to collect information about how users use a
							    website, for instance which pages users go to most often, and if they get
							    error messages from web pages. These cookies don’t collect information that
							    identifies a visitor. The data from such cookies are aggregated and
							    anonymised, which means that we cannot identify a user as an individual. We
							    ask for your consent before these types of cookies are used.
							</p>
							<p>
							    Cookies used
							</p>
							<ul>
							    <li>
							        _gat
							    </li>
							    <li>
							        _gid
							    </li>
							    <li>
							        _ga
							    </li>
							</ul>
							<h2 className="m-top">
							    TARGETING COOKIES
							</h2>
							<p>
							    These Cookies will collect information about a user’s browsing habits and
							    allow the website owner to show the user adverts while they are browsing
							    the website or other websites on the internet. They are set by the website
							    owner or by third parties acting on behalf of the website owner and last
							    for variable periods of time.
							</p>
							<p>
							    They may be used to deliver targeted advertisements to website users or to
							    limit the number of times a visitors will see an ad. They are usually
							    placed by advertising networks with the website operator’s permission. They
							    remember that a visitor has visited a website and this information is
							    shared with other organisations such as advertisers.
							</p>
							<table border="0" cellSpacing="0" cellPadding="0">
							    <tbody>
							        <tr>
							            <td width="120">
							                <p align="center">
							                    Categories
							                </p>
							            </td>
							            <td>
							                <p align="center">
							                    Cookies
							                </p>
							            </td>
							        </tr>
							        <tr>
							            <td>
							                <p>
							                    doubleclick.net
							                </p>
							            </td>
							            <td>
							                <ul>
							                    <li>
							                        id
							                    </li>
							                </ul>
							            </td>
							        </tr>
							    </tbody>
							</table>
							<p className="m-top">
							    <strong><em>How You Can Manage Your Cookie Preferences</em></strong>
							</p>
							<p>
							    You can manage your Cookie preferences via our Cookie Preference Centre.
							</p>
							<p className="m-top"></p>
					</OverflowContent>
				</Col>
			</Row>
			<Row>
				<Col xs={{size:12}}  lg={{size:12}} >	
					<BackBtn onClick={props.closeLegal}> 
						BACK TO SIGN UP
					</BackBtn>
				</Col>
			</Row>
		</React.Fragment>
    )
}

const TermsOfUse = (props) => {

    return (
        <React.Fragment>
			<Helmet>
            	<title>Thought Leadership: Terms of Use</title>
    			<meta name="description" content="Description"/>
    		</Helmet>
			<Row>
				<Col xs={{size:12}}  lg={{size:12}} >					
					<TitleContainer>
						<h1>Terms of Use</h1>
					</TitleContainer>
				</Col>
				<Col  xs={{size:12}}  lg={{size:12}} >
					<OverflowContent>
							<h2>INTRODUCTION</h2>
							<p>McCann Worldgroup (“McCann Worldgroup”) provides to you this website (“Site”) for your personal informational, educational and entertainment use. The following Terms of Use govern your access and use of this Site. Please read these Terms of Use carefully before using this Site. Also, specific terms and conditions may apply to specific Content (as defined below) on or available through this Site (the “Content”) and to specific content, data, materials, or information you may upload, submit and/or publish to the Site (“User Content”). Such specific terms may be in addition to these Terms of Use or, where and then only to the extent expressly specified, may supersede these Terms of Use.
							</p>
							<p className="m-top">When used in these Terms of Use, “we” and “our” means McCann Worldgroup, and “you” and “your” refers to any individual, company or legal entity that accesses or otherwise uses this Site.</p>

							<h2 className="m-top">1. ACCEPTANCE OF TERMS OF USE</h2>
							<p>By accessing, browsing or using this Site you agree to be bound by these Terms of Use and all terms and conditions contained or referenced herein or any additional terms and conditions set forth on this Site. If you do NOT agree to all of these terms, you should NOT access or use this Site.</p>
							<h2 className="m-top">2. MODIFICATION OF TERMS</h2>
							<p>These Terms of Use may be amended by McCann Worldgroup, in its sole discretion, at any time without notice to you. Such amended Terms of Use shall be effective upon posting. By continuing to access or use this Site, you will be deemed to have accepted such amendments. You are advised to regularly review any applicable terms and conditions. Other McCann Worldgroup websites may have their own terms of use which apply to such websites.</p>
							<h2 className="m-top">3. PRIVACY NOTICE</h2>
							<p>Personal information provided through this Site shall be used in accordance with McCann Worldgroup’s Privacy Notice and these Terms of Use are subject to the Privacy Notice as posted on this Site.</p>
							<h2 className="m-top">4. COPYRIGHT AND TRADEMARKS</h2>
							<p>You acknowledge that all content on this Site, including, without limitation, the Site’s design, graphics, text, formatting, sounds, pictures, images, user interfaces, page headers, custom graphics, button icons, scripts, software, trademarks, service marks, trade names, trade dress, logos, reports, analyses, and other materials and information on this Site, and the selection and arrangement thereof (collectively, the “Content”), is owned, controlled or licensed by or to McCann Worldgroup, and is protected by United States and international copyright, trademark and various other intellectual property laws and rights. Except as expressly authorized in these Terms of Use, you may not copy, reproduce, distribute, republish, download, perform, display, post, transmit, exploit, create derivative works or otherwise use any of the Content in any form or by any means, without the prior written authorization of McCann Worldgroup. McCann Worldgroup authorizes you to view and download the Content, provided that (i) you use the Content only for your own personal, non-commercial use, (ii) you keep intact all copyright and other proprietary notices contained therein, and (iii) you do not modify or adapt the Content in any way. All rights to the Content not expressly granted in these Terms of Use are expressly reserved to McCann Worldgroup.</p>
							<h2 className="m-top">5. LINKS TO THIRD PARTY WEBSITES</h2>
							<p>Links on the Site to third party websites are provided only as a convenience to you and are not an endorsement by McCann Worldgroup. When you click on these links, you will leave the Site. We are in no way responsible for examining or evaluating these third-party sites. You agree that McCann Worldgroup and its affiliates will not be responsible or liable for any content, goods or services provided on or through these outside websites or for your use of or inability to use such websites. You use and access these third party website at your own risk. McCann Worldgroup recommends that you make yourself aware of and read the legal and privacy notices of all the third party websites that you visit.</p>
							<h2 className="m-top">6. DISCLAIMER OF WARRANTIES</h2>
							<p>McCann Worldgroup strives to provide accurate and up-to-date material on this Site. However, we make no warranties or representations as to the accuracy or timeliness of the Content on this Site.</p>
							<p className="m-top">
								THE SITE AND THE CONTENT CONTAINED ON THIS SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. IPG EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. MCCANN WORLDGROUP MAKES NO WARRANTY THAT (I) THE SITE WILL MEET YOUR REQUIREMENTS, (II) THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE WILL BE ACCURATE OR RELIABLE, (IV) THE SITE IS FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS, OR (V) ANY ERRORS IN THE SITE WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THIS SITE IS PROVIDED AT YOUR OWN RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE SITE, INCLUDING WITHOUT LIMITATION, DAMAGES RESULTING FROM COMPUTER VIRUSES.
							</p>
							<p className="m-top">SOME JURISDICTIONS MAY NOT PERMIT CERTAIN DISCLAIMERS OF WARRANTIES, SO SOME OF THE EXCLUSIONS ABOVE MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, WE DISCLAIM WARRANTIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p>
							<h2 className="m-top">7. DISCLAIMER OF DAMAGES AND LIMITATION OF LIABILITY</h2>
							<p>NEITHER MCANN WORLDGROUP OR ITS AFFILIATES SHALL UNDER ANY CIRCUMSTANCES BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF, IN CONNECTION WITH OR RELATING TO YOUR ACCESS TO, OR USE OF OR INABILITY TO USE THIS SITE OR ANY MATERIAL, OR ANY OTHER WEBSITE YOU ACCESS THROUGH A LINK FROM THIS SITE, OR ANY INCORRECT OR INACCURATE INFORMATION ON THIS SITE. THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL DAMAGES OF ANY KIND, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING BUT NOT LIMITED TO PROPOERTY DAMAGE, LOSS OF USE, LOSS OF DATA, LOSS OF BUSINESS, ECONOMIC LOSS OR LOSS OF PROFITS), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF MCCANN WORLDGROUP HAS BEEN ADVISED OF THE POSSIBLITY OF SUCH DAMAGE OR LOSS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY WAIVE ALL CLAIMS AGAINST MCCANN WORLDGROUP AND ITS AFFILIATES AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, SUPPLIERS AND PROGRAMMERS THAT MAY ARISE FROM YOUR ACCESS OR USE OF THIS SITE.</p>
							<h2 className="m-top">8. INDEMNIFICATION AND RELEASE</h2>
							<p>
								You agree to indemnify, defend and hold harmless McCann Worldgroup and its affiliates against all claims, demands, causes of action, losses, expenses, damages and costs, including any reasonable attorneys’ fees, resulting or arising from or relating to your use of or conduct on the Site, any activity related to use of the Site by you, any message or material that you submit to, post on or transmit through the Site, your violation of these Terms of Use, your infringement or violation of any rights of another, or termination of your access to the Site.
							</p>
							<p className="m-top">If you have a dispute with one or more users, you release McCann Worldgroup and its affiliates from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>
							<h2 className="m-top">9. NO UNLAWFUL OR PROHIBITED ACTIVITY</h2>
							<p>
								As a condition of your use of this Site, you agree not to use the Site for any purpose that is unlawful or prohibited by these terms and conditions. You further agree that you are responsible for your use of and communications on the Site. You agree not to post on or transmit through this Site any unlawful, infringing, threatening, harassing, defamatory, vulgar, obscene, profane, indecent, offensive, hateful or otherwise objectionable material of any kind, including any material that encourages criminal conduct or conduct that would give rise to civil liability, violates the privacy rights of others, infringes others’ intellectual property rights or otherwise violates any applicable local, state, national or international law. You agree not to use this Site in any manner that interferes with its normal operation or with any other user’s use and enjoyment of the Site. You agree to use reasonable efforts to scan and remove any viruses or other contaminating or destructive features before submitting any material.
							</p>
							<p className="m-top">McCann Worldgroup reserves the right, in its sole discretion, to suspend or terminate your access to this Site and prohibit any and all current and future use of this Site (or any portion thereof) by you, if you fail to comply with any term or provision of these Terms of Use or your use is harmful to the interests of another user of this Site.</p>
							<h2 className="m-top">10. SITE SERVICE</h2>
							<p>McCann Worldgroup reserves the right at any time and from time to time to add, modify, delete or discontinue, temporarily or permanently, the Site (or any part thereof) with or without notice or consent. McCann Worldgroup and its affiliates shall have no responsibility or liability for failure to store or delete any Content or User Content submitted to the Site.</p>
							<h2 className="m-top">11. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
							<p>These Terms of Use shall be governed by, and construed in accordance with, the laws of the State of New York, U.S.A., without giving effect to any principles of conflicts of law. McCann Worldgroup makes no representations that the material and information on this Site are appropriate or available in all national locations or languages. You agree that any action at law or in equity arising from or relating to the use of this Site or to these Terms of Use shall be brought exclusively in the Federal or State Courts sitting in New York. You hereby consent and submit to personal jurisdiction in of such courts for the purposes of any action relating to this Site, your access or use thereof, or these Terms of Use, and to extra-territorial service of process.</p>
							<h2 className="m-top">12. SEVERABILITY</h2>
							<p>If any provision of these Terms of Use is held to be unlawful, void, or for any reason unenforceable by a court of competent jurisdiction, then the invalid or unenforceable provision shall be replaced by a valid, enforceable provision that most closely matches the intent of the original provision, and the validity and enforceability of any remaining provisions shall not be affected.</p>
							<h2 className="m-top">13. NO WAIVER</h2>
							<p>
								The failure of McCann Worldgroup and its affiliates to enforce any part of these Terms of Use shall not constitute a waiver of such term or provision, and shall not be considered a waiver or limit McCann Worldgroup’s right thereafter to insist upon strict adherence to that term or any other provision of these Terms of Use.
							</p>
							<p className="m-top">Last Update: August 2019</p>					
							<p className="m-top"></p>					
					</OverflowContent>
				</Col>
			</Row>
			<Row>
				<Col xs={{size:12}}  lg={{size:12}} >	
					<BackBtn onClick={props.closeTerms}> 
						BACK TO SIGN UP
					</BackBtn>
				</Col>
			</Row>
		</React.Fragment>
    )
}


class SignUpForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            termsOpen: false,
            legalTextOpen: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Fetch a new Article if params change
    }

    openTerms = (e) => {
        this.setState({ termsOpen: true })
        e.preventDefault();
    }

    closeTerms = (e) => {
        this.setState({ termsOpen: false })
        e.preventDefault();
    }

    openLegal = (e) => {
        this.setState({ legalTextOpen: true })
        e.preventDefault();
    }

    closeLegal = (e) => {
        this.setState({ legalTextOpen: false })
        e.preventDefault();
    }


    render() {
        if (this.props.signUpSuccess) {
            return (
                <SignUpSuccess>
					<Row>
						<Col lg={{size:10, offset:1}} >
							<p>
								<strong>Thank you.</strong> We will send you a confirmation email, to access the site.
							</p>
							<p>
								If you don’t receive an email shortly, please make sure to check your spam folder.
							</p>
							<Button onClick={this.props.handleClose}>Close</Button>
						</Col>
					</Row>
				</SignUpSuccess>
            )
        } else {
            return (
                <React.Fragment>
						{
							!!this.state.termsOpen &&
								<TermsOfUse closeTerms={this.closeTerms} />

						}
						{

							!!this.state.legalTextOpen &&
								<LegalText closeLegal={this.closeLegal} />
						}
						<Row style={{ display: this.state.termsOpen || this.state.legalTextOpen ? 'none' : 'inherit'}} >
							<Col xs={{size:12}}  lg={{size:12}} >					
								<TitleContainer>
									<h4>Sign Up to Read.</h4>
									<h5>Provide info below to access content:</h5>
								</TitleContainer>
								<Formik
								initialValues={{ 
									firstName: '', lastName: '', businessName: '', title:'', phoneNumber:'', email:'', zipCode:'', terms: false, interests:[] }}
								validate={values => {
										let errors = {};

										
										
										// First name
										if(!values.firstName){
											errors.firstName = 'First name is required';
										}else if( values.firstName.length < 2 ){
											errors.firstName = 'First name is not valid';
										}

										// Last name
										if(!values.lastName){
											errors.lastName = 'Last name is required';
										}else if( values.lastName.length < 2 ){
											errors.lastName = 'Last name is not valid';
										}

										// Business name
										if(!values.businessName){
											errors.businessName = 'Business name is required';
										}else if( values.businessName.length < 2 ){
											errors.businessName = 'Business name is not valid';
										}

										// Title name
										if(!values.title){
											errors.title = 'Title is required';
										}else if( values.title.length <= 1 ){
											errors.title = 'Title is not valid';
										}

										// Phone number
										if( values.phoneNumber.toString().length > 0 && values.phoneNumber.toString().length !== 10   ){
											errors.phoneNumber = 'Phone number should contain 10 digit';
										}

										// Email field
										if (!values.email) {
										errors.email = 'Enter a valid email';
										} else if (
										!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
										) {
										errors.email = 'Invalid email address';
										}

										// Zip number
										if(!values.zipCode){
											errors.zipCode = 'Postal code number is required';
										}else if( values.zipCode.toString().length !== 5  ){
											errors.zipCode = 'Postal code number should contain 5 digit';
										}

										// Terms 
										if(!values.terms){
											errors.terms = 'Confirm you have read the Terms of Use and Privacy Notice.';
										}
										console.log(errors);
										return errors;
								}}
								onSubmit={(values, {props, setSubmitting  }) => {

									setTimeout( ()=>{
										this.props.signUp(values)
										setSubmitting(false);
									},3000 )
								}}
								>
								{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										/* and other goodies */
								}) => (
									<Form onSubmit={handleSubmit}>
										<input type="text" autoFocus={true} style={{display:'none'}}  />
											<Row>
												<Col xs={{size:12}} md={{size:6}} lg={{size:6}}>
													<Fieldset>
														<Field
														component={Input}
														type="text"
														name="firstName"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.firstName}
														label={"First Name"}
														error={errors.firstName}
														/>
														<ErrorMessage>
															{errors.firstName && touched.firstName && errors.firstName}
														</ErrorMessage>
													</Fieldset>
												</Col>
												<Col xs={{size:12}} md={{size:6}}  lg={{size:6}}>
													<Fieldset>
														<Field
															component={Input}
															type="text"
															name="lastName"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.lastName}
															label={"Last Name"}
															/>
															<ErrorMessage>
																{errors.lastName && touched.lastName && errors.lastName}
															</ErrorMessage>
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Col lg={{size:12}}>
													<Fieldset>
														<Field
																component={Input}
																type="text"
																name="businessName"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.businessName}
																label={"Business Name"}
																/>
																<ErrorMessage>
																	{errors.businessName && touched.businessName && errors.businessName}		
																</ErrorMessage>
			
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Col lg={{size:12}}>
													<Fieldset>
														<Field
																component={Input}
																type="text"
																name="title"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.title}
																label={"Title"}
																/>
																<ErrorMessage>
																	{errors.title && touched.title && errors.title}		
																</ErrorMessage>
			
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Col lg={{size:12}}>
													<Fieldset>
														<Field
																component={Input}
																type="number"
																name="phoneNumber"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.phoneNumber}
																label={"Phone Number (optional)"}
																/>
																<ErrorMessage>
																	{errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}		
																</ErrorMessage>
			
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Col lg={{size:12}}>
													<Fieldset>
														<Field
																component={Input}
																type="email"
																name="email"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.email}
																label={"Email"}
																/>
																<ErrorMessage>
																	{errors.email && touched.email && errors.email}		
																</ErrorMessage>
			
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Col lg={{size:12}}>
													<Fieldset>
														<Field
																component={MultiSelectChecboxes}
																type="select"
																name="interests"
																onChange={handleChange}
																onBlur={handleBlur}
																options={this.props.interests}
																id={"interests"}
																/>
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Col xs={{size:8}} sm={{size:6}}  md={{size:6}} lg={{size:6}}>
													<Fieldset>
														<Field
																component={Input}
																type="number"
																name="zipCode"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.zipCode}
																label={"Zip / Postal Code"}
																/>
																<ErrorMessage>
																	{errors.zipCode && touched.zipCode && errors.zipCode}		
																</ErrorMessage>
			
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Col lg={{size:12}}>
													<Fieldset>
														<Field 	name="terms"
																id="terms"
																label={""}
																component={Checkbox} />
																<Label htmlFor={'terms'}>
																	I agree to McCann Worldgroup’s <span onClick={this.openTerms}>Terms of Use</span> and <span onClick={this.openLegal}>Privacy Notice</span>
																</Label>
																<ErrorMessage>
																	{errors.terms && touched.terms && errors.terms}		
																</ErrorMessage>
													</Fieldset>
												</Col>
											</Row>
											<Row>
												<Submit type="submit" disabled={isSubmitting}> 
													SIGN UP
													{
														!!isSubmitting && <Icon><LoadingIcon /></Icon>
													}
												
												</Submit>
											</Row>
									</Form>
								)}
								</Formik>
							</Col>
					</Row>
				</React.Fragment>
            )
        }




    }
}

const mapStateToProps = (state) => ({
    signUpSuccess: state.signUpSuccess,
});

const mapDispatchToProps = (dispatch) => ({
    signUp: (data) => dispatch(signUp(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);