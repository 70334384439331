import { SIGN_UP_REQUEST, SIGN_UP_SUCCESS, COMPLETE_SIGN_UP_REQUEST, COMPLETE_SIGN_UP_SUCCESS, USER_DATA } from '../actions/User/types';


export function signUpRequest(state = false, action) {
  switch(action.type) {
    case SIGN_UP_REQUEST:
      return action.loading;
    default:
      return state;
  }
}
  
export function signUpSuccess(state = false, action) {
  switch(action.type) {
    case SIGN_UP_SUCCESS:
      return action.signUpSuccess;
    default:
      return state;
  }
}

export function completeSignUpRequest(state = false, action) {
  switch(action.type) {
    case COMPLETE_SIGN_UP_REQUEST:
      return action.loading;
    default:
      return state;
  }
}

export function completeSignUpRequestSuccess(state = false, action) {
  switch(action.type) {
    case COMPLETE_SIGN_UP_SUCCESS:
      return action.loading;
    default:
      return state;
  }
}

export function user(state = {}, action) {
  switch(action.type) {
    case USER_DATA:
      return action.user;
    default:
      return state;
  }
}
 