import {  MENU_MOBILE_IS_OPEN}  from '../actions/Menu/types';


export function menuIsOpen(state = false, action) {
    switch(action.type) {
        case MENU_MOBILE_IS_OPEN:
        return action.menuIsOpen;
        default:
        return state;
    }
}