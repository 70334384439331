import {Book, Heavy, Light, Medium, MediumOblique } from './Futura-Std.js';

const Fonts = `
    ${Book} 
    ${Heavy}
    ${Light}
    ${Medium},
    ${MediumOblique}
`;

export default Fonts;
