import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeMenu } from './../../actions/Menu';
import { Container as C, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import Modal from 'react-responsive-modal';

const ModalContainer = styled.div `
  background-color: ${props => props.theme.colors.black.regular};
  margin: 0;
  width: 100%;
  max-width: unset;
`;

const Container = styled(C)
`
  padding-bottom:1.875rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 35px;  
`;

const UL = styled.ul `
  list-style-type:none;
  text-align: center;
  text-align:center;
  li{
		line-height: normal;
    font-family: 'Futura LT W01 Medium', Futura, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: .5em;
    padding: 20px 0;
    a{
      color:${props=>props.theme.colors.white.regular};
      position: relative;
    }

  @media screen and ( min-width: 768px ) {
    font-size: 22px;
    padding: 24px 0;
  }
`;

/**
 * Modal styles found on /styles/_Modal.scss
 */
class MenuMobileModal extends Component {
    constructor(props) {
        super(props)
        this.handleClose = this.handleClose.bind(this);


        this.state = {
            opacity: 0,
            isOpen: this.props.menuIsOpen
        };
    }


    handleClose() {
        this.props.closeMenu(true);
    }

    render() {
        return (
            <Modal        open={this.props.menuIsOpen}
                          onClose={()=>this.handleClose()}
                          closeIconId={"modal-close-icon-mobile"}
                          classNames={{
                            overlay: 'modal-overlay-mobile-tablet',
                            modal: 'modal-body-mobile-tablet',
                          }}
 
            >
              <ModalContainer>
                <Container  fluid="true">
                  <Row className={"no-gutters"}>
                    <Col xs={{size:12}} sm={{size:12}}  lg={{size:12}} >
                      <UL>
                        <li><a href="https://www.mccannworldgroup.com/work">WORK</a></li>
                        <li><a href="https://www.mccannworldgroup.com/expertise">EXPERTISE</a></li>
                        <li><a href="https://www.mccannworldgroup.com/about">ABOUT</a></li>
                        <li><a href="https://www.mccannworldgroup.com/news">NEWS</a></li>
                        <li><a href="https://www.mccannworldgroup.com/careers">CAREERS</a></li>
                        <li><a href="https://www.mccannworldgroup.com/contact">CONTACT</a></li>
                      </UL>
                    </Col>
                  </Row>
                </Container>
              </ModalContainer>
          </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    menuIsOpen: state.menuIsOpen
});

const mapDispatchToProps = (dispatch) => ({
    closeMenu: (isOpen) => dispatch(closeMenu(isOpen))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobileModal);