import {  SIGNUP_MODAL_IS_OPEN}  from '../actions/Modal/types';


export function modalIsOpen(state = false, action) {
    switch(action.type) {
        case SIGNUP_MODAL_IS_OPEN:
        return action.modalIsOpen;
        default:
        return state;
    }
}