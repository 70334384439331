import {  SIGNUP_MODAL_IS_OPEN } from './types';


export function modalIsOpen(bool) {
    return {
        type: SIGNUP_MODAL_IS_OPEN,
        modalIsOpen: bool
    }
}



export function openModal(  ) {
    return (dispatch) => {
        dispatch( modalIsOpen(true) )
    };
}

export function closeModal(  ) {
    return (dispatch) => {
        dispatch( modalIsOpen(false) )
    };
}
  
  