import React from 'react';
import styled from 'styled-components';

import { mediaBreakpointDownLg, mediaBreakpointDownMd, mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";
const P = styled.p`
    text-align:right;
    font-family: ${props => props.theme.font.medium};
    letter-spacing: 0.1em;
    font-size:14px;
    ${mediaBreakpointDownLg`
        text-align:center;
        color:black;
        line-height:70px;
        font-size: 18px;
    `}
    ${mediaBreakpointDownMd`
        font-size: 18px;
    `}
    ${mediaBreakpointDownXs`
        line-height:37.5px;
        font-size: 12px;
    `}
`;
const UserWelcome =  ( props )=>{
    return(
        <P>Welcome back, {props.user.data.first_name} {props.user.data.last_name}</P>
    )
}

export default UserWelcome