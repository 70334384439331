import  { GENERAL_DATA_FETCH_DATA_SUCCESS } from '../actions/General/types';


export function generaldata(state = false, action) {
    switch(action.type) {
      case GENERAL_DATA_FETCH_DATA_SUCCESS:
        return action.generaldata;
      default:
        return state;
    }
  }
  