import React from 'react';
import styled from 'styled-components';
import { mediaBreakpointDownLg, mediaBreakpointDownMd, mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";

const P = styled.p `
    text-align:right;
    font-family: ${props => props.theme.font.medium};
    letter-spacing: 0.1em;
    font-size: 0.875em;
    cursor:pointer;
    ${mediaBreakpointDownLg`
        text-align:center;
        color:black;
        line-height:70px;
        font-size: 18px;
    `}
    ${mediaBreakpointDownMd`
        font-size: 18px;
    `}
    ${mediaBreakpointDownXs`
        line-height:37.5px;
        font-size: 12px;
    `}
    span{
        border-bottom: 1px solid ${props => props.theme.colors.white.regular};
        ${mediaBreakpointDownLg`
             border-bottom: 1px solid ${props => props.theme.colors.black.regular};
        `}
    }
`;

const UserLogin = (props) => {
    return (
        <P onClick={()=> props.openModalHandler(true)}>Register to Read: <span>Sign Up</span></P>
    )
}

export default UserLogin