import Fonts from './Fonts';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    
    ${Fonts}
    button:focus{
        outline: none;
    }
    a:focus {
        outline: none;
    }
    textarea:focus, input:focus{
        outline: none;
    }

    a:hover {
        text-decoration: none;
    }

    
    [class^="icon-"]:before, [class*=" icon-"]:before {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;      
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
                    
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;        
    }



    // Simple reset
    html, body, div, span, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    abbr, address, cite, code,
    del, dfn, em, img, ins, kbd, q, samp,
    small, strong, sub, sup, var,
    b, i,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section, summary,
    time, mark, audio, video {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        font-size:100%;
        vertical-align:baseline;
        background:transparent;
    }
    html, body{
        font-family: FuturaStd-Book;
        font-size: 100%;
        box-sizing: border-box;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    // Reset Guttenber WP blocks css
    .wpg-blocks .wpg-block > * {
        max-width:  none !important;
    }


    h1,h2,h3{
        font-family: FuturaStd-Heavy;
        font-size: 1em;
    }

    p{
        font-family: FuturaStd-Medium;
    }

    .wpg-blocks .wpg-block>*{
        margin-bottom:12px;
        margin-top:12px;
    }

    .wpg-block.wpg-b_paragraph{
        margin-bottom:40px;
        @media and ( max-width: 767px ){
            margin-bottom:60px;            
        }
    }

    .wp-block-image {
         img { 
            width: auto !important;
            height: auto !important;
        }
    }

    @media screen and ( max-width: 768px ){
        .wp-block-image {
            margin-bottom: 80px !important;
            figure{
                float:none !important;
                margin: auto !important;
            }
            
        }
    }

    @media screen and ( min-width: 1400px ){
        .separator-transparent{
            margin-top: 150px !important;
            display:block;
        }
    }

    .wpg-blocks{
        margin-top:3.75rem;
        @media screen and ( max-width: 767px ){
            margin-top:3rem;
        }
        h4{
            font-family: FuturaStd-Heavy;
            font-size: 24px;
            @media screen and ( max-width: 767px ){
                font-size: 22px;
            }
        }

        p{
            line-height:36px;
            letter-spacing: 0.02em;
            em{
                font-family: FuturaStd-Medium-Oblique;
                font-size: 18px;
                color: black;
                font-style: normal;
            }
        }
    }

    .wpg-blocks .wpg-block>*{
        max-width: unset;
    }

`;


export default GlobalStyles;
