import React from 'react'
import styled from 'styled-components'
import {  Row as R, Col, Container} from '@bootstrap-styled/v4';
import { ChevronBig } from './../Icons';
import TweenLite from 'gsap';
import scrollTo from './../../../node_modules/gsap/ScrollToPlugin';
import { mediaBreakpointOnlyXs } from "styled-bootstrap-responsive-breakpoints";

let SloganSection = styled.div`
    background-color: ${ props=> props.theme.colors.black.regular}
`;

let Row = styled(R)`
    padding: 3rem 0 50px 0;
    ${
        mediaBreakpointOnlyXs`
            padding-top: 95px;
            padding-bottom:90px;
        `
    }
`;

let P = styled.p`
    font-family: ${props=>props.theme.font.book}
    color: ${props=>props.theme.colors.white.regular}
    font-size: 1.6875rem;
    line-height: 2.5rem;
    letter-spacing: 1.6px;
    padding: 1.9375rem 0 40px 0;
    ${
        mediaBreakpointOnlyXs`
            margin: 0px 2%;
            font-size: 0.96875rem;
            line-height: 1.5625rem;
            letter-spacing: 0.15625rem;
            max-width: 570px;
            padding-top: 0;
            padding-bottom: 50px       
        `
    }

}


`;

const ScrollTo = () =>{
    TweenLite.to(window, .4,   {scrollTo: {y:".slogan-section", offsetY:90} })

}

const Slogan = () => {
   return(
       <SloganSection className="slogan-section">
            <Container>
                    <Row className="text-center">
                        <Col lg={{size:12}}>
                            <P>Featured Thought Leadership insights, connecting strategy, business and human experiences:</P>
                            <ChevronBig height="26" onClick={ScrollTo} />
                        </Col>
                    </Row>
            </Container>
       </SloganSection>
        )
}

export default Slogan;