import React, { Component } from 'react';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { BrowserRouter as Router, Route, Switch   } from "react-router-dom";
import Home from './containers/Home';
import Article from './containers/Article';
import './fontello/css/fontello.css';

import GlobalStyles from './styles/globalStyles';


const store = configureStore(
    {
        modalIsOpen:false,
        article: []
    }
    );

class App extends Component {
    
    render(){
        return (
            <Provider store={store}>
                <Router  basename="/">
                <Switch>
                    <Route key="home" path="/" exact component={Home} />
                    <Route key="validate-login"  path="/complete-signup/*" component={Home} />
                    <Route path="/article/*" component={Article} />
                </Switch>
                </Router>
                <GlobalStyles />
            </Provider>
        )
    }
}

export default App;
