import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {Helmet} from "react-helmet";


import { articlesFetchData } from '../actions/Article';
import { completeSignUp } from '../actions/User';
import { openModal } from '../actions/Modal';


import  Hero  from '../components/Hero';
import  Slogan  from '../components/Slogan';
import ArticlesContainer from '../components/ArticlesContainer';
import LayoutContainer from '../components/LayoutContainer';
import Contact from '../components/Footer/Contact';


class Home extends Component {
  constructor(props){
    super(props)
    this.state = {
      modal: false
    };
    this.handleClose  = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.props.fetchData(`${process.env.REACT_APP_WP_API_URL}/wp-json/wp/v2/articles`);

    // If url contain params, complete the signup.
    let jwt = this.props.match.params ? this.props.match.params[0] : '';
    if( jwt !== undefined  ){
      this.props.completeSignUp(jwt);
    }

    if( this.props.location.state != undefined && this.props.location.state.signUpWarning  ){
        this.props.openModal(true)
    }
  }

  handleClose = () => this.setState({ modal: !this.state.modal });

  render() {
    return (
      <LayoutContainer>
        <Helmet>
            <title>McCann Worldgroup | Thought Leadership </title>
            <meta name="description" content="McCann Worldgroup is a leading global marketing solutions network united across 100+ countries by a single vision: To help brands play a meaningful role in people’s lives."/>
        </Helmet>
        <Hero isMobile={this.props.isMobile}/>
        <Slogan />
        <ArticlesContainer user={this.props.user } articles={this.props.articles} />
        <Contact />
      </LayoutContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  articles: state.articles,
  hasErrored: state.articlesHasErrored,
  articlesLoading: state.articlesLoading,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (isOpen)=>dispatch( openModal(isOpen) ),
  fetchData: (url) => dispatch(articlesFetchData(url)),
  completeSignUp: ( jwt ) => dispatch( completeSignUp(jwt) )
});

export default withRouter( connect(mapStateToProps, mapDispatchToProps)(Home));
