import React from 'react'
import styled from 'styled-components';

let Svg = styled.svg`
  cursor:pointer;
`;

const DownloadIcon = props => (
  <Svg width={30} height={38} {...props}>
    <path
      fillRule="evenodd"
      d="M14.998 28.002l-5.999-6.004h11.998l-5.999 6.004z"
    />
    <path fillRule="evenodd" d="M14 0h2v23h-2V0zM0 36h30v2H0v-2z" />
  </Svg>
)

export default DownloadIcon
