import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from '@bootstrap-styled/v4';
import { mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";
import moment from 'moment';

let thisYear = moment().year();

const FooterContainer = styled.div`
    background:${props=>props.theme.colors.black.light};
    color:white;
    padding:50px 0;
    ${mediaBreakpointDownXs`
        background:${props=>props.theme.colors.blue.dark};
    `}

    
`;

const SocialMediaIconsList = styled.ol`
    margin-top:1.875rem;
    list-style-type:none;
    display:flex;
    flex-flow: row wrap;

    ${mediaBreakpointDownXs`
        margin-top:1rem;
    `}


    li{
        margin-left:0.875rem;
        background:#636464;
        border-radius:100%;
        width:45px;
        height:45px;
        color:black;
        line-height: 42px;
        font-size: 21px;
        text-align: center;
        position:relative;
        :hover{
            background: #fff;
        }
        a{
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            text-indent: -9999em;
            overflow: hidden;
            width: 100%;
            height: 100%;
            color: #fff;
            margin:0;
        }
        ${mediaBreakpointDownXs`
            display: inline-block
        `}

    }
    margin-bottom:1em;
    ${props=>props.showOnMobile === true && `
        display:none;
     `}
    ${mediaBreakpointDownXs`
     ${props=>props.showOnMobile === false && `
        display:none;
     `}

     ${props=>props.showOnMobile === true && `
        justify-content: center;
        padding-right: 2em;
        display:inherit;
        li{
            width:30px;
            height:30px;
            line-height: 30px;
            font-size: 16px;
        }
     `}




    `}
`;


const P = styled.p`
    font-family: ${props=>props.theme.font.medium};
    font-size:0.688rem;
    line-height:0.859rem;
    letter-spacing:.3rem;
`;

const CompanyName = styled(P)`
    ${mediaBreakpointDownXs`
        order:2
        margin: 4.063em 0;
    `}
`;

const LinksFooter = styled(P)`
    margin-left:25px;

    button,a{
        color:white;
    }

    button,
    a.only-xs{
        display:none;
        ${mediaBreakpointDownXs`
            display:block;
        `}
    }



    span{
        margin:0 16px;
    }

    ${mediaBreakpointDownXs`
        order:1
        span{
            display:none;
        }
        margin-left: 0;
        width: 100%;
        display:flex;
        flex-flow: column wrap;
        font-family: ${props=>props.theme.font.book};
        text-align:center;
        button{
            background:transparent;
            border:none;
            margin: 20px 0;
            position:relative;
            font-family: ${props=>props.theme.font.medium};
            font-size:0.688rem;
            line-height:0.859rem;
            letter-spacing:.3rem;
            &:after{
                content:"";
                position:absolute;
                top: 30px;
                left:25%;
                right:25%;
                margin:auto;
                height:1px;
                background: ${props=> props.theme.colors.white.regular };
                width:10%;
                opacity:.5;
            }


            ${props=> props.followMenuOpen ? `
                &:after{
                    top:80px
                }
                margin-bottom:0;
            ` : ``}
        }
        
        a{
            margin: 20px 0;
            position:relative;
            &:after{
                content:"";
                position:absolute;
                top: 30px;
                left:25%;
                right:25%;
                margin:auto;
                height:1px;
                background: ${props=> props.theme.colors.white.regular };
                width:10%;
                opacity:.5;
            }
            
        }


    `}
`;

class Footer extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            followMenuOpen: false
        }

        this.toggleFollowMobileMenu = this.toggleFollowMobileMenu.bind(this);
    }
    
    toggleFollowMobileMenu = () =>{
        this.setState( {followMenuOpen: !this.state.followMenuOpen})
    }
    render(){

        return(
            <div>
                <FooterContainer>
                    <Container>
                        <Row className="justify-content-center">
                            <Col>
                                <Row className="justify-content-center">
                                    <CompanyName>
                                        @{thisYear} MCCANN WORLDGROUP
                                    </CompanyName>
                                    <LinksFooter followMenuOpen={this.state.followMenuOpen}>
                                        <button className="only-xs"  onClick={this.toggleFollowMobileMenu}>
                                            FOLLOW US
                                            { this.state.followMenuOpen && 
                                                <SocialMediaIconsList showOnMobile={true}>
                                                    <li className="fb">
                                                        <a href="https://www.facebook.com/McCannMW/" target="_blank"  rel="noopener noreferrer" >Facebook</a>
                                                        <i className="icon-facebook"></i>
                                                    </li>
                                                    <li className="li">
                                                        <a href="https://www.linkedin.com/company/mccann-worldgroup" target="_blank"  rel="noopener noreferrer">LinkedIn</a>
                                                        <i className="icon-linkedin"></i>
                                                    </li> 
                                                    <li className="ig">
                                                        <a href="https://www.instagram.com/mccann_mw/" target="_blank"  rel="noopener noreferrer">Instagram</a>
                                                        <i className="icon-instagram"></i>
                                                    </li> 
                                                </SocialMediaIconsList>
                                            }
                                        </button>
                                        <a href="/">PRIVACY POLICY</a>
                                        <span>|</span>
                                        <a href="/">TERMS OF USE</a>
                                    </LinksFooter>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <SocialMediaIconsList showOnMobile={false}>
                                <li className="fb">
                                <a href="https://www.facebook.com/McCannMW/" target="_blank"  rel="noopener noreferrer" >Facebook</a>
                                <i className="icon-facebook"></i>
                                </li>
                                <li className="li">
                                    <a href="https://www.linkedin.com/company/mccann-worldgroup" target="_blank"  rel="noopener noreferrer">LinkedIn</a>
                                    <i className="icon-linkedin"></i>
                                </li> 
                                <li className="ig">
                                    <a href="https://www.instagram.com/mccann_mw/" target="_blank"  rel="noopener noreferrer">Instagram</a>
                                    <i className="icon-instagram"></i>
                                </li> 
                            </SocialMediaIconsList>
                        </Row>
                    </Container>
                </FooterContainer>
            </div>
        )
    }
}


export default Footer;