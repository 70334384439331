import { combineReducers } from 'redux';
import { articles, articlesHasErrored, articlesLoading, article } from './articles';
import { generaldata } from './generalData';
import { signUpRequest, signUpSuccess, completeSignUpRequest, completeSignUpRequestSuccess, user } from './user';


import { modalIsOpen } from './modal';

import { menuIsOpen } from './menu';

export default combineReducers({
  modalIsOpen,
  articles,
  article,
  articlesHasErrored,
  articlesLoading,
  generaldata,
  signUpRequest,
  signUpSuccess,
  completeSignUpRequest,
  completeSignUpRequestSuccess,
  user,
  menuIsOpen
});