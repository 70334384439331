import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 21px;
  height: 21px;
  line-height: 0;
  background: ${props => props.theme.colors.white.regular}
  border:2px solid ${props =>  props.error ? '#ad2d32' : props.theme.colors.black.regular}
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

const Label = styled.label`
  margin-left:10px;
  text-decoration: underline;
  ${mediaBreakpointDownXs`
    margin-left: 2vw;
  `}
  @media screen and (max-width:410px){
    font-size: 3.7vw;
  }
`;

const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    ...props
  }) =>{
    const handleChange=()=>{
      setFieldValue(name, !value)
    
    }
    let error = errors && errors.terms ? true : false;
    return (
    <React.Fragment>
        <CheckboxContainer  className={className} onClick={()=> handleChange() }>
          <HiddenCheckbox 
            name={name}
            id={id}
            type="checkbox"
            value={value}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
       
            />
          <StyledCheckbox  error={error} checked={value} onClick={()=> onChange }>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
        <Label htmlFor={name}>{label}</Label>
    </React.Fragment>
)}

export default Checkbox
