import { createStore, compose, applyMiddleware  } from 'redux';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';
import allReducers from '../reducers';
import promise from 'redux-promise';
import { loadState, saveState } from './localStorage';

const logger = createLogger();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();


export default function configureStore(initialState) {
    
  const store = createStore(
    allReducers,
    persistedState,
    composeEnhancer( applyMiddleware(thunk, logger, promise) )
  );

  // Inside of index.js ...
  store.subscribe(() => {
    saveState(store.getState())
  })

  return store;
}