import React from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';


const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height:20px;
  height:22px;
`

const Icon = styled.svg`
  fill: black;
  width:100%;
  height:100%;
  border:3px solid white;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 21px;
  height: 21px;
  line-height: 0;
  background: ${props => props.theme.colors.white.regular}
  border:2px solid ${props => props.theme.colors.black.regular}
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`

const Label = styled.label`
  font-size: 1rem;
  color:black;
  line-height:1.125rem;
  font-family: ${props=>props.theme.font.medium};
  margin-bottom:0.75rem;
`;


const MultiSelectCheckboxLabel = styled.label`
    line-height:1.3125rem;
    margin-left: 0.4375rem;
    padding-left:0.375rem;
    vertical-align: middle;
    font-size: 0.875rem;
    font-family: ${props=>props.theme.font.medium};
  `;

let DropDownArrow = styled.span`
   right: 25px!important;
   top: 14px!important;
   ${props=>props.isOpen && `
      transform: rotate(180deg);
   `}

`;



const Option = ( props )=> {
  return (
    <div>
      <components.Option {...props}>
        <CheckboxContainer >
          <StyledCheckbox checked={props.isSelected}>
            <Icon >
              <rect width="100%" height="100%" />
            </Icon>
          </StyledCheckbox>
          <MultiSelectCheckboxLabel isSelected={props.isSelected} >{props.label} </MultiSelectCheckboxLabel>
        </CheckboxContainer>
      </components.Option>
    </div>
  );
}
  



const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ececec",
      color:'black',
      width:'100%',
      // match with the menu
      borderRadius: '26px',
      // Overwrittes the different states of border
      borderColor: '#7e7d7d',
      // Removes weird border around container
      boxShadow: 'none',
      overflow: 'hidden',
      maxWidth: '300px',
      minHeight: '52px',
      height: '52px',
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "inherit" : "inherit"
      },
      position:'relative',
      paddingLeft: '17px'
    }),
    menu: base => ({
      ...base,
      // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
      hyphens: "auto",
      // kill the gap
      marginTop: 0,
      textAlign: "left",
      // prevent menu to scroll y
      wordWrap: "break-word",
      color: 'black',
      background:'#ececec',
      marginLeft:'25px',
      width:'100%',
      border: '1px solid #7e7d7d',
      borderTop:'0px solid transparent',
      borderRadius:'0.3125rem',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingLeft: '1.3rem',
      paddingTop: '0.625rem',
      paddingBottom: '1.25rem',
      maxWidth: '250px',
      position:'static',
      boxShadow: 'none'
    }),
    option: (base, state ) =>({
        ...base,
        color: state.isSelected ? 'black' : '#636161',
        padding: '4.5px',
        backgroundColor: state.isSelected ? 'transparent' : 'transparent',
        ':active': {
          backgroundColor: state.isSelected ? 'transparent' : 'transparent'
        }
    }),
    indicatorSeparator: base =>({
        ...base,
        display:'none'
    }),
    indicatorsContainer: base =>({
      ...base,
      position: 'absolute',
      left:0,
      top:0,
      width:'100%',
      height: '52px'
  })
  }


  const ValueContainer = ({ children, getValue, ...props }) => {
    var valueLength = getValue().length;
    var optionsLength = props.selectProps.options.length;
  
    // Check if values are equal to options and display `All` instead of number.
    valueLength = optionsLength === valueLength ? "All" : valueLength;
  
    return (
      <components.ValueContainer {...props}>
        {!props.selectProps.inputValue &&
          valueLength === 'All' || valueLength > 0 ? `Select (${valueLength})` : 'Select'
        }
        {React.Children.map(children, child => {
          return child.type === components.Input ? child : null;
        })}
      </components.ValueContainer>
    );
  };



  
  
class MultiSelectChecboxes extends React.Component{

  constructor( props ){
      super(props)
      this.state = {
        optionsSelected : [],
        menuIsOpen: false
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleClick = this.handleClick.bind(this);
  }
  
  ControlComponent = props => (
    <div >
      <components.Control {...props} />
    </div>
  );

  DropdownIndicator = (
    props
  ) => {
    return (
      <components.DropdownIndicator className={'select-custom'} {...props} onClick={this.handleClick}>
           <DropDownArrow isOpen={props.selectProps.menuIsOpen}  className="select-custom-arrow"></DropDownArrow>
      </components.DropdownIndicator>
    );
};

  handleChange = value => {
    this.props.form.setFieldValue('interests', value)
  };

  handleClick = () => {
    console.log("handleClcik")
    this.setState( {menuIsOpen : !this.state.menuIsOpen})
  }

  render(){
    let {
      field: { name},
      id,
      options
  } = this.props;
 

  


  let optionsField =  options !== undefined && options.interests ?  options.interests.map( interest => ({ value: interest.id, label: interest.name  })  ) : [];
    return(
      <React.Fragment>
        <div style={{position:'relative'}} >
          <Label htmlFor={"interests"}>
            What are you interested in? Select all that apply
          </Label>
          <Select
              isClearable={false}
              id={id}
              closeMenuOnSelect={false}
              components={{  Control: this.ControlComponent ,DropdownIndicator: this.DropdownIndicator, Option:Option, ValueContainer:ValueContainer}}
              options={optionsField}
              className={name}
              name={name}
              onChange={this.handleChange}
              backspaceRemovesValue={false}
              placeholder={"Select"}
              styles={customStyles}
              isMulti
              hideSelectedOptions={false}
              isSearchable={false}
              openMenuOnClick={true}
              
          />
        </div>
      </React.Fragment>   
    )
  }


};
  
export default MultiSelectChecboxes;