import React, { Component } from 'react';
import { Link as L, withRouter  } from 'react-router-dom'
import { connect } from 'react-redux';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {DownloadIcon as DI, ChevronSmall } from './../Icons';
import { mediaBreakpointDownXs, mediaBreakpointDownMd, mediaBreakpointDownLg } from "styled-bootstrap-responsive-breakpoints";

let ArticleHeaderContainer = styled.div`
    height: 110px;
    background : ${props=>props.theme.colors.gray.light};
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 90px;
    ${mediaBreakpointDownXs`
        display:none;
    `}
    ${mediaBreakpointDownMd`
        margin-top: 90px;
        height: 70px;
    `}
    ${mediaBreakpointDownLg`
        margin-top: 90px;
        height: 70px;
    `}
    
`;

let ContainerWrapper = styled(Container)`
    
    @media screen and ( min-width:1200px){
        width:1075px;
        max-width:1075px;
        margin:auto;
    }
    

    @media screen and ( min-width:1400px){
        width:1250px;
        max-width:1250px;
        margin:auto;
    }
`;


let ArticleTtitle = styled.h1`
    font-size: 0.875rem;
    line-height:1.063rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    max-width: 330px;
    color: #000;
    margin-left: -70px;
    ${mediaBreakpointDownMd`
        margin-left: 0;
        font-size: 13px;
    `}
    ${mediaBreakpointDownLg`
        margin-left: -25px;
    `}

`;


let PrevArticleButton = styled(ChevronSmall)`
    fill:red !important;
    width:16px;
`;

let NextArticleButton = styled(ChevronSmall)`
    margin-left: 1.675rem;
    width:16px;
`;

let DownloadButton = styled.button`
    font-size: 0.875rem;
    border:none;
    background:transparent;
    font-family: ${props=>props.theme.font.bolder};
    color: ${props=>props.theme.colors.black.regular};
    margin-right: -7px;
    letter-spacing: 0.1rem;
    cursor:pointer;
    ${props=> props.disabled && `
        opacity:.8
    `}
    &:hover{
        text-decoration: underline;
        color: inherit;
    }

    ${mediaBreakpointDownMd`
        font-size: 13px;
    `}
   

`;

let Link = styled(L)`
    ${props=>props.disabledlink === 'disabled' ? `
        user-select: none;
        pointer-events: none;
    ` : ''}
`;

let DownloadIcon = styled(DI)`
    margin-left: 0px;
    ${mediaBreakpointDownLg`
        margin-left: 8px;
    `}
`;


let PrevArticle = (props) =>{
    let disabledlink = ( !!props.postSlug ?  '' : 'disabled'  );
    let ButtonStyle = {
        fill: ( disabledlink ? '#bdbdb9' : 'black'),
        height: '31px',
        stroke:'#e5e5e1',
        style:{ transform:'rotate(180deg)'}
    }

    return(
        <Link disabledlink={disabledlink} to={{pathname:`/article/${props.postSlug}`}}>
            <PrevArticleButton {...ButtonStyle}  />
        </Link>
    )
}

let NextArticle = (props) =>{
    let disabledlink = ( !!props.postSlug ?  '' : 'disabled'  );  
    
    
    let ButtonStyle = {
        fill: ( disabledlink ? '#bdbdb9' : 'black'),
        height: '31px',
        stroke:'#e5e5e1',
    }
    
    return(
        <Link disabledlink={disabledlink} to={{pathname:`/article/${props.postSlug}`}}>
            <NextArticleButton {...ButtonStyle} />
        </Link>
    )

}

const ArticleNavigation = (props) => {
    let nextPrevLinks = props.nextPrevLinks;
    let next = nextPrevLinks && nextPrevLinks.next ? nextPrevLinks.next  : ''; 
    let prev = nextPrevLinks && nextPrevLinks.previous ? nextPrevLinks.previous  : '';
    let NavigationContent = styled.div`
    padding-left: 16%;
    @media screen and ( min-width: 1200px ){
        padding-left: 25px;            
    }
    `;

    return(
        <NavigationContent>
            <PrevArticle postSlug={next.slug}/>
            <NextArticle postSlug={prev.slug} />
        </NavigationContent>
    )
}

class ArticleHeader extends Component {


    
    DownloadAttachment = (link)=>{
        window.open(link);
    }

    render(){
        let downloadText = this.props.generaldata.downloadButtonText ? this.props.generaldata.downloadButtonText : 'DOWNLOAD NOW';
        let article = this.props.article ? this.props.article : ''; 
        let nextPrevLinks = article ? article.navigation : '';

        let attachment = this.props.article  ? this.props.article.attachment : '';
        let title = this.props.article && this.props.article.title ? this.props.article.title.rendered : '';
        return(
            <ArticleHeaderContainer>
                <ContainerWrapper className="p-0">
                    <Row className={"align-items-center"}>
                        <Col sm={{size:2}} md={{size:2}} lg={{size:2}}  >
                            <ArticleNavigation nextPrevLinks={nextPrevLinks} />
                        </Col>
                        <Col sm={{size:6}}  md={{size:6}} lg={{size:6}}>
                            <ArticleTtitle>{ title }</ArticleTtitle>
                        </Col>
                        <Col sm={{size:4}} md={{size:4}} lg={{size:4}}  className={"text-right"}>
                            <DownloadButton disabled={ !attachment ? 'disabled' : '' }  onClick={ () => {  this.DownloadAttachment(attachment) } }>
                                {downloadText}
                                <DownloadIcon/>
                            </DownloadButton>
                        </Col>

                    </Row>
                </ContainerWrapper>
            </ArticleHeaderContainer>
        )
    }
}

const mapStateToProps = (state) => ({
    article: state.article,
    articles: state.artciles,
    hasErrored: state.articlesHasErrored,
    isLoading: state.articlesIsLoading
});

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter ( connect(mapStateToProps, mapDispatchToProps)(ArticleHeader));