import { ARTICLES_FETCH_DATA_SUCCESS, ARTICLES_LOADING, ARTICLES_HAS_ERRORED, ARTICLE_FETCH_DATA_SUCCESS } from '../actions/Article/types';


export function articlesHasErrored(state = false, action) {
    switch(action.type) {
      case ARTICLES_HAS_ERRORED:
        return action.hasErrored;
      default:
        return state;
    }
  }
  
  export function articlesLoading(state = false, action) {
    switch(action.type) {
      case ARTICLES_LOADING:
        return action.loading;
      default:
        return state;
    }
  }
  
  export function articles(state = [], action) {
    switch(action.type) {
      case ARTICLES_FETCH_DATA_SUCCESS:
        return action.articles;
      default:
        return state;
    }
  }

  export function article(state = [], action) {
    switch(action.type) {
      case ARTICLE_FETCH_DATA_SUCCESS:
        return action.article[0];
      default:
        return state;
    }
  }