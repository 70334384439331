
const underlined = props => `
    position:relative;
        &:after{
            content: "";
            position: absolute;
            width: 24px;
            background: ${ !!props.color ? props.color : 'white'  };
            left: ${ !!props.left ? props.left : '48.8%'  };
            bottom: ${ !!props.left ? props.bottom : '-1rem'  };
            height: 2px;
        }
    margin-bottom: 2rem;
`;


export default underlined;