import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'airbnb-js-shims';

import "react-gutenberg/default.css"

import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';


import * as serviceWorker from './serviceWorker';


import './styles/App.scss';
import App from './App.js';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
