import { SIGN_UP_REQUEST, SIGN_UP_SUCCESS, COMPLETE_SIGN_UP_REQUEST, COMPLETE_SIGN_UP_SUCCESS, USER_DATA  } from './types';


export function signUpRequest(bool) {
    return {
        type: SIGN_UP_REQUEST,
        loading: bool
    }
}



export function signUpSuccess(bool) {
    return {
        type: SIGN_UP_SUCCESS,
        signUpSuccess: bool
    }
}

export function completeSignUpRequest(bool){
    return {
        type: COMPLETE_SIGN_UP_REQUEST,
        loading: bool
    }
}

export function completeSignUpSuccess(bool){
    return {
        type: COMPLETE_SIGN_UP_SUCCESS,
        loading: bool
    }
}

export function userData(data){
    return {
        type: USER_DATA,
        user: data
    }
}

/**
 *  Validate the JWT into the backend and response with user data; 
*/
export function completeSignUp(jwt){

    return (dispatch) => {
        dispatch(completeSignUpRequest(true));
        let url=`${process.env.REACT_APP_WP_API_URL}/wp-json/mccann/v1/auth/validate`;

        fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: JSON.stringify({jwt:jwt}), // body data type must match "Content-Type" header
        })
        .then((response) => response.json())
        .then((user) => { 
            return dispatch(userData(user))} 
        )
        .catch((error) => {
            return dispatch(completeSignUpSuccess(false));
        })
    };
}

export function signUp(data) {
    return (dispatch) => {
        dispatch(signUpRequest(true));
        let url=`${process.env.REACT_APP_WP_API_URL}/wp-json/mccann/v1/auth`;

        fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(response => {
            dispatch(signUpSuccess(true));
            response.json()
        })
        .catch(() => dispatch(signUpSuccess(false)));
    };
}