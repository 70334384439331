import React, { Component } from 'react';
import { connect } from 'react-redux';
import { articleFetchData } from '../actions/Article';
import { withRouter  } from 'react-router-dom'

import LayoutContainer from '../components/LayoutContainer';
import ArticleHeader from './../components/ArticleHeader';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import underlined from './../styles/mixings.js';
import WPGBlocks from 'react-gutenberg'
import { Route, Redirect } from 'react-router'
import { mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";
import TweenLite from 'gsap';
import scrollTo from './../../node_modules/gsap/ScrollToPlugin';


let ArticleContentContainer = styled(Container)`
  padding-top: 90px;
  padding-bottom: 140px;
  max-width: 1200px;

  @media screen and ( min-width: 1400px ){
    max-width: 1030px;
  }


  .wp-article-block {
    max-width: 810px;
    margin: auto;
    @media screen and ( min-width: 1200px ) and ( max-width: 1399px){
      min-width: 788px;
      padding: 0;
      width: 788px;
      margin:unset;
    }
    @media screen and ( min-width: 1400px ){
      min-width: 1030px;
      padding: 0;
      width: 1030px;
      margin:unset;

    }
    
  }
  p, h4{
    color: black;
    letter-spacing: 0.125rem!important;
  }

  p{
    font-size: 1.125rem;
    ${mediaBreakpointDownXs`
      font-size: 0.9375rem;
      line-height: 1.6875rem!important;
      letter-spacing: 0!important;
    `}
  }

  h4{
    ${mediaBreakpointDownXs`
      font-size: 1.25rem;
      line-height: 1.6875rem;
    `}
  }

  a{
      color: #000;
      text-decoration:underline;
      :hover{
          text-decoration:underline;
          color:initial;
      }
  }
`;


let ArticleTitle = styled.h2`
  font-size: 2.25rem;
  line-height: 2.75rem;
  letter-spacing: 0.125rem;
  color: black;
  ${underlined({color:'black', left:'0', bottom:'-15px'})}
  margin-bottom:2rem;
  ${mediaBreakpointDownXs`
    font-size: 1.625rem;
    line-height: 2.375rem;
    letter-spacing: 0.23125rem;
  `}
`;

let Author = styled.h3`
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.125rem;
  color: black;
  ${mediaBreakpointDownXs`
    font-size: 0.75rem;
    line-height: 1.125rem;
  `}
`;

let Position = styled.span`
  display:block;
`;


class Article extends Component {
  constructor(props){
    super(props)
    this.state = {
      redirect: false
    }
    this.fetchArticle = this.fetchArticle.bind(this);
  }

  componentDidMount() {
    TweenLite.to(window, .4,  {scrollTo: {y:0, autoKill:false} })
    if( Object.getOwnPropertyNames(this.props.user).length > 0 ){
      this.fetchArticle();
    }else{
      this.setState({redirect:true})
    }
    this.fetchArticle();
    

  }

  componentDidUpdate(prevProps, prevState) {
    // Fetch a new Article if params change
    if (prevProps.match.params[0] !== this.props.match.params[0]) {
      this.fetchArticle()
    }
  }

  fetchArticle(){
    let articleSlug = this.props.match.params[0];
    this.props.fetchArticle(`${process.env.REACT_APP_WP_API_URL}/wp-json/wp/v2/articles?slug=${articleSlug}`);

  }

  render() {
    let article = this.props.article || {};
    let author = this.props.article && this.props.article.author_data ? this.props.article.author_data : {} ;
    if( this.state.redirect ){
      return <Redirect to={{
        pathname: '/',
        state: { signUpWarning: true }
    }} />
    }else{
      return (
        <LayoutContainer locationPath={this.props.match.path}>
          <ArticleHeader />
  
          { 
            !this.props.isLoading ? 
              ( 
                <ArticleContentContainer>
                  <Row>
                    <Col xs={{size:10, offset:1}} lg={{size:11, offset:1}} className={"wp-article-block"} >
                      <ArticleTitle> { article.title ? article.title.rendered : '' } </ArticleTitle>
                      <Author>
                          {author.first_name }   {author.last_name }
                          <Position>
                            {author.position }
                          </Position>
                      </Author>
                      {
                        article.content ? <WPGBlocks blocks={article.blocks} /> : 'no content yet'
                        
                      }
                    </Col>
                  </Row>
                </ArticleContentContainer>
              ) : 'loading...'
          }
        </LayoutContainer>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  article: state.article,
  hasErrored: state.articlesHasErrored,
  isLoading: state.articlesIsLoading,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  fetchArticle: (url) => dispatch(articleFetchData(url))
});

export default withRouter( connect(mapStateToProps, mapDispatchToProps)(Article));
