import React, { Component } from 'react';
import { Container as C , Row, Col } from '@bootstrap-styled/v4';
import ArticleCover  from './ArticleCover';
import styled from 'styled-components';
import { mediaBreakpointOnlyXs } from "styled-bootstrap-responsive-breakpoints";

const ArticleWrapper = styled.div`
    position:relative;
    padding-top:1.250rem;
    background: ${props=>props.theme.colors.white.regular}
    
    ${
        mediaBreakpointOnlyXs`
         padding-top: 0;
         padding-bottom:0px;

        `
    }
    
    
`;

const FloatingTopSeparator = styled.div`
    position: absolute;
    top: -0.063em;
    left: 0;
    right: 0;
    background:  ${ props=> props.theme.colors.black.regular};
    height: 20%;
    max-height:11.563em;
    z-index: 0;

    ${
        mediaBreakpointOnlyXs`
            height: 7%;
            top: -2.063em;

        `
    }
`;


const Container = styled(C)`
    max-width:840px;
    @media screen and (min-width:1400px) {
        max-width: 1032px;
    }

    @media screen and (min-width:1200px)  {
        padding:0;
    }
}

`;


/**
 *  @input Articles from WP populated on props.articles with redux connect
 *  @output Maximum 4 post on the grid, if there are user logged show 1 more feature post on the hero.
 */
class ArticlesContainer extends Component{
    
    render(){

        let articles = this.props.articles;

        let user = this.props.user;
        let userLogged = Object.getOwnPropertyNames(user).length > 0;
        if( userLogged ){
            articles = articles.slice(1);
        }else{
            articles = articles.slice(0, -1);
        }


        return(
            <ArticleWrapper className="articles-container">
                <FloatingTopSeparator></FloatingTopSeparator>
                <Container className={'p-0'}>
                    <Row noGutters={true} className="d-flex justify-content-center">
                        { articles ?

                                articles.map( (article, key) =>{
                                let colProps = {
                                    lg:{
                                        size:6,
                                    },
                                    md:{
                                        size:5
                                    },
                                    sm:{
                                        size:10,
                                    },
                                    xs:{
                                        size:8
                                    }
                                };

                                let isLast = ( this.props.articles.length === (key+1)  )
                                return(
                                    <Col key={key} {...colProps} >
                                        <ArticleCover  key={key} isLast={isLast} article={article} articleKey={key}   />
                                    </Col> 
                                )
                            })
                            :
                            <p style={{ fontSize:'80px'}}>Loading articles ...</p>
                        }
                    </Row>
                </Container>
            </ArticleWrapper>
        )
    }
}

export default ArticlesContainer;