import React from 'react'
import styled from 'styled-components';

let Svg = styled.svg`
  cursor:pointer;
`;
const ChevronSmall = props => {
  let IconStyleDefault = {
    fill:  (props.fill ? props.fill : '#fff'),
    stroke: ''
  };
  return(
    <Svg viewBox="0 0 10 16" {...props} style={{ ...props.style}}>
      <path
        {... IconStyleDefault}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8l-8.09 8L0 14.11 6.21 8 0 1.89 1.91 0z"
      />
    </Svg>
  )
}
export default ChevronSmall
