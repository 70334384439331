import { ARTICLES_FETCH_DATA_SUCCESS, ARTICLES_LOADING, ARTICLES_HAS_ERRORED, ARTICLE_FETCH_DATA_SUCCESS } from './types';


export function articleHasErrored(bool) {
    return {
      type: ARTICLES_HAS_ERRORED,
      hasErrored: bool
    }
  }
  
  export function articlesLoading(bool) {
    return {
      type: ARTICLES_LOADING,
      loading: bool
    }
  }
  
  export function articlesFetchDataSuccess(articles) {
    return {
      type: ARTICLES_FETCH_DATA_SUCCESS,
      articles
    }
  }

  export function articleFetchDataSuccess(article) {
    return {
      type: ARTICLE_FETCH_DATA_SUCCESS,
      article
    }
  }
  
  /* es6 sintaxis
  export const itemsFetchDataSuccess = (items) => ({
    type: 'IEMS_FETCH_DATA_SUCESS',
    items
  })*/
  
  export function articlesFetchData(url) {
    return (dispatch) => {
      dispatch(articlesLoading(true));
      fetch(url)
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(articlesLoading(false));
            return response;
        })
        .then((response) => response.json())
        .then((items) => dispatch(articlesFetchDataSuccess(items)))
        .catch(() => dispatch(articleHasErrored(true)));
    };
  }

  export function articleFetchData(url) {
    return (dispatch) => {
      dispatch(articlesLoading(true));
      fetch(url)
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(articlesLoading(false));
            return response;
        })
        .then((response) => response.json())
        .then((article) => dispatch(articleFetchDataSuccess(article)))
        .catch(() => dispatch(articleHasErrored(true)));
    };
  }