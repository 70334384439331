import FuturaStdBook_Eot from './../../assets/fonts/FuturaStd/Book/FuturaStd-Book.eot';
import FuturaStdBook_Ttf from './../../assets/fonts/FuturaStd/Book/FuturaStd-Book.ttf';
import FuturaStdBook_Svg from './../../assets/fonts/FuturaStd/Book/FuturaStd-Book.svg';
import FuturaStdBook_Woff from './../../assets/fonts/FuturaStd/Book/FuturaStd-Book.woff';
import FuturaStdBook_Woff2 from './../../assets/fonts/FuturaStd/Book/FuturaStd-Book.woff2';


import FuturaStdHeavy_Eot from './../../assets/fonts/FuturaStd/Heavy/FuturaStd-Heavy.eot';
import FuturaStdHeavy_Ttf from './../../assets/fonts/FuturaStd/Heavy/FuturaStd-Heavy.ttf';
import FuturaStdHeavy_Svg from './../../assets/fonts/FuturaStd/Heavy/FuturaStd-Heavy.svg';
import FuturaStdHeavy_Woff from './../../assets/fonts/FuturaStd/Heavy/FuturaStd-Heavy.woff';
import FuturaStdHeavy_Woff2 from './../../assets/fonts/FuturaStd/Heavy/FuturaStd-Heavy.woff2';


import FuturaStdLight_Eot from './../../assets/fonts/FuturaStd/Light/FuturaStd-Light.eot';
import FuturaStdLight_Ttf from './../../assets/fonts/FuturaStd/Light/FuturaStd-Light.ttf';
import FuturaStdLight_Svg from './../../assets/fonts/FuturaStd/Light/FuturaStd-Light.svg';
import FuturaStdLight_Woff from './../../assets/fonts/FuturaStd/Light/FuturaStd-Light.woff';
import FuturaStdLight_Woff2 from './../../assets/fonts/FuturaStd/Light/FuturaStd-Light.woff2';

import FuturaStdMedium_Eot from './../../assets/fonts/FuturaStd/Medium/FuturaStd-Medium.eot';
import FuturaStdMedium_Ttf from './../../assets/fonts/FuturaStd/Medium/FuturaStd-Medium.ttf';
import FuturaStdMedium_Svg from './../../assets/fonts/FuturaStd/Medium/FuturaStd-Medium.svg';
import FuturaStdMedium_Woff from './../../assets/fonts/FuturaStd/Medium/FuturaStd-Medium.woff';
import FuturaStdMedium_Woff2 from './../../assets/fonts/FuturaStd/Medium/FuturaStd-Medium.woff2';


import FuturaStdMediumOblique_Eot from './../../assets/fonts/FuturaStd/Medium-Oblique/FuturaStd-MediumOblique.eot';
import FuturaStdMediumOblique_Ttf from './../../assets/fonts/FuturaStd/Medium-Oblique/FuturaStd-MediumOblique.ttf';
import FuturaStdMediumOblique_Svg from './../../assets/fonts/FuturaStd/Medium-Oblique/FuturaStd-MediumOblique.svg';
import FuturaStdMediumOblique_Woff from './../../assets/fonts/FuturaStd/Medium-Oblique/FuturaStd-MediumOblique.woff';

export const MediumOblique = `
@font-face {
font-family: 'FuturaStd-Medium-Oblique';
src: url(${FuturaStdMediumOblique_Eot});
src: url(${FuturaStdMediumOblique_Eot}?#iefix) format('embedded-opentype'),
			url(${FuturaStdMediumOblique_Woff}) format('woff'),
			url(${FuturaStdMediumOblique_Ttf}) format('truetype'),
			url(${FuturaStdMediumOblique_Svg}#FuturaStd-Book) format('svg');
		}
`;


export const Book = `
@font-face {
font-family: 'FuturaStd-Book';
src: url(${FuturaStdBook_Eot});
src: url(${FuturaStdBook_Eot}?#iefix) format('embedded-opentype'),
			url(${FuturaStdBook_Woff2}) format('woff2'),
			url(${FuturaStdBook_Woff}) format('woff'),
			url(${FuturaStdBook_Ttf}) format('truetype'),
			url(${FuturaStdBook_Svg}#FuturaStd-Book) format('svg');
		}
`;

export const Heavy = `
@font-face {
font-family: 'FuturaStd-Heavy';
src: url(${FuturaStdHeavy_Eot});
src: url(${FuturaStdHeavy_Eot}?#iefix) format('embedded-opentype'),
			url(${FuturaStdHeavy_Woff2}) format('woff2'),
			url(${FuturaStdHeavy_Woff}) format('woff'),
			url(${FuturaStdHeavy_Ttf}) format('truetype'),
			url(${FuturaStdHeavy_Svg}#FuturaStd-Heavy) format('svg');
}`;
		
export const Light = `
@font-face {
font-family: 'FuturaStd-Light';
src: url(${FuturaStdLight_Eot});
src: url(${FuturaStdLight_Eot}?#iefix) format('embedded-opentype'),
			url(${FuturaStdLight_Woff2}) format('woff2'),
			url(${FuturaStdLight_Woff}) format('woff'),
			url(${FuturaStdLight_Ttf}) format('truetype'),
			url(${FuturaStdLight_Svg}#FuturaStd-Light) format('svg');
}`;

export const Medium = `
@font-face {
	font-family: 'FuturaStd-Medium';
	src: url(${FuturaStdMedium_Eot});
	src: url(${FuturaStdMedium_Eot}?#iefix) format('embedded-opentype'),
				url(${FuturaStdMedium_Woff2}) format('woff2'),
				url(${FuturaStdMedium_Woff}) format('woff'),
				url(${FuturaStdMedium_Ttf}) format('truetype'),
				url(${FuturaStdMedium_Svg}#FuturaStd-Medium) format('svg');
	}
`;


