import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from './../../actions/Modal';
import { Container as C, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { mediaBreakpointDownXs } from "styled-bootstrap-responsive-breakpoints";
import SignUpForm from './../Forms/SignUpForm';
import Modal from 'react-responsive-modal';



const ModalContainer = styled.div `
  background-color: ${props => props.theme.colors.white.regular};

  ${mediaBreakpointDownXs`
    width:90%;
    margin:auto;
  `}

`;

// const FloatedCloseIcon = styled.div`
//   position:relative;
//   > svg {
//     position:absolute;
//     right:2.0625rem;
//     top:1.6875rem;
//     height:20px;
//     z-index:2;
//   }
// `;

const Container = styled(C)
`
  padding-top: .5rem;  
  padding-bottom:1.875rem;
  
`;

/**
 * Modal styles found on /styles/_Modal.scss
 */
class SignUpModal extends Component {
    constructor(props) {
        super(props)
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);


        this.state = {
            opacity: 0,
            isOpen: this.props.modalIsOpen
        };
    }


    componentDidMount() {
        var closeIconSvg = document.getElementById('modal-close-icon');

        if (closeIconSvg != null && closeIconSvg.childNodes.length > 0) {
            console.log("child node", closeIconSvg.childNodes[0])
            closeIconSvg.childNodes[0].setAttribute("viewBox", "0 0 24 24");
        }
    }

    componentDidUpdate() {
        var closeIconSvg = document.getElementById('modal-close-icon');

        if (closeIconSvg != null && closeIconSvg.childNodes.length > 0) {
            console.log("child node", closeIconSvg.childNodes[0])
            closeIconSvg.childNodes[0].setAttribute("viewBox", "0 0 24 24");
        }
    }


    handleClose() {
        this.props.closeModal(true);
    }

    handleClick() {
        console.log("clicked component")
    }

    render() {
        return (
            <Modal        open={this.props.modalIsOpen}
                          onClose={()=>this.handleClose()}
                          closeIconId={"modal-close-icon"}
                          closeIconSvgPath={<path className="st0" d="M1,25L25,1 M25,25L1,1"/>}
                          classNames={{
                            overlay: 'modal-overlay',
                            modal: 'modal-body',
                          }}
 
            >
              <ModalContainer>
                <Container>
                  <Row>
                    <Col xs={{size:12}} sm={{size:10, offset:1}}  lg={{size:10, offset:1}} >
                      <SignUpForm onClick={this.handleClick} handleClose={ () => this.handleClose()  } interests={this.props.interests || []}/>
                    </Col>
                  </Row>
                </Container>
              </ModalContainer>
          </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    modalIsOpen: state.modalIsOpen
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: (isOpen) => dispatch(closeModal(isOpen))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);