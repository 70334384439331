let Theme = {
    colors :{
        gray: {
            regular: '#666666',
            regular_2: '#636161',
            light:'#e5e5e1',
            lighter:'#e5e5e5',
            ligter_7d: "#7e7d7d",
            dark:'#333333'
        },
        green: {
            regular:'#1b292c'
        },
        black: {
            regular:'#000000',
            light: '#1b292c'
        },
        white: {
            regular:'#ffffff'
        },
        red: {
            regular: '#ad2d32',
            cake: '#993333'
        },
        blue: {
            regular:'#82c7d3',
            dark:'#1b292c'
        },
        yellow:{
            regular: '#fefc09'
        }
    },
    font:{
        bolder:'FuturaStd-Heavy',
        medium:'FuturaStd-Medium',
        mediumOblique:'FuturaStd-Medium',
        book: 'FuturaStd-Book'
    },
    '$container-max-widths':{
        lg:'840px'
    },
    '$grid-gutter-width': '21px',
    '$body-bg': '#000'
};

export default Theme;