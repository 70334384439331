import React from 'react'
import styled from 'styled-components';
import closeIcon from '../../assets/images/close-icon.png';

let Svg = styled.svg`
  cursor:pointer;
`;

const CloseIcon = props => (
	<i className={"icon-close"} style={{
		color: "#fefc09", 
		fontSize: "21px", 
		right: "0",
		lineHeight: "47px", 
		position: "absolute",
		textAlign: "center", 
		textIndent: "0",
		top: "0",
		width: "100%"
	}} aria-label="close menu"></i>
)

export default CloseIcon
