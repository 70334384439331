import React from 'react'
import styled from 'styled-components';

let Svg = styled.svg`
  cursor:pointer;
`;

const ChevronBig = props => (
  <Svg viewBox="0 0 20.77 11.15" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
      d="M20.17 0l-9.78 9.95L.6 0 0 .67l10.39 10.48L20.77.58z"
    />
  </Svg>
)

export default ChevronBig
