import React  from 'react';
import { Container as C, Row, Col } from '@bootstrap-styled/v4';
import { mediaBreakpointDownXs, mediaBreakpointDownLg} from "styled-bootstrap-responsive-breakpoints";

import styled from 'styled-components';

const ContactSection = styled.div`
    background:${props=>props.theme.colors.blue.regular};
    color:${props=>props.theme.colors.black.regular};
    padding-bottom:86px;

    ${mediaBreakpointDownXs`
        padding-bottom:100px;
    `}

    @media screen and ( min-width: 1400px ){
        padding-bottom:130px;
        margin-top: -25px;
    }
`;

const P = styled.p`
    padding-top:7.5rem;
    padding-bottom:4.063rem;
    font-size:1.688rem;
    font-family: ${props=>props.theme.font.book}
    letter-spacing: 0.05em;
    padding-left:3.913rem;
    padding-right: 3.913rem

    ${mediaBreakpointDownXs`
        font-size:1.4rem;
        padding-left:.5rem;
        padding-right: .5rem
    `}

    ${mediaBreakpointDownLg`
        font-size: 1.600rem;
    `}

`;


const ContactSlotRow = styled(Row)`
    background:${props=>props.theme.colors.white.regular};
    color:${props=>props.theme.colors.black.regular};
    padding:45px 0px;
    padding-left: 10px;
    padding-right: 10px;
    span{
        display:block;
    }
    ${mediaBreakpointDownXs`
        padding:50px 0px;
        margin-left: 2% !important;
        margin-right: 2% !important;
        > div{
            &:last-child{
                margin-top: 6.563em;
            }
        }
    `}
`;

const ContactTitle = styled.h5`
    font-size:0.875em;
    font-family: ${props=>props.theme.font.bolder};
    letter-spacing:0.32em;
    position:relative;
    &:after{
        content: "";
        width: 27px;
        position: absolute;
        background: ${props=>props.theme.colors.black.regular};
        height: 3px;
        bottom: -16px;
        left: 0;
        right: 0;
        margin: auto;
    }
    margin-bottom:32px;
`;

let Container = styled(C)`
    @media screen and (min-width: 1200px){
        max-width: 840px;
    }
    @media screen and (min-width: 1400px){
        max-width: 992px;
    }
`;

let ContactName = styled.h6`
    font-family: ${props=>props.theme.font.bolder};
    font-size: 1.313rem;
    letter-spacing:0.1rem;
    line-height:1.375rem;
    margin-bottom:0.625rem;
`;

let PhoneNumber = styled.p`
    font-family: ${props=>props.theme.font.bolder};
    font-size:0.875rem;
    line-height:1.688rem;
    letter-spacing:0.2rem;
`;

let Email = styled.p`
    font-family: ${props=>props.theme.font.medium};
    font-size:0.688rem;
    line-height:1.625rem;
    letter-spacing:0.2rem;
    &:hover{
        text-decoration:underline;
    }
    a {
        color: #000;
    }
`;

let ContactInfoBox = styled.div`
    
`;

const Contact = ( props )=>{
    let newBussinessData = props.generaldata.contact!== undefined ? props.generaldata.contact.new_bussiness : { name: '', phone:''}
    let mediaInquiries = props.generaldata.contact !== undefined ? props.generaldata.contact.media_inquiries : { name: '', phone:''}
    let phrase = props.generaldata.contact !== undefined ? props.generaldata.contact.phrase : '' 
    return(
        <ContactSection>
            <Container className={"text-center"}>
                <Row className={'justify-content-center'}>
                    <Col>
                        <P>
                            {phrase || ''}
                        </P>
                    </Col>
                </Row>
                <ContactSlotRow>
                    <Col xs={"12"} sm={"6"} md={"6"} lg={"6"}>
                        <ContactInfoBox>
                            <ContactTitle>NEW BUSINESS</ContactTitle>
                            <ContactName>{newBussinessData.name || ''}</ContactName>
                            <PhoneNumber>{newBussinessData.phone || ''}</PhoneNumber>
                            <Email><a href={"mailto:" + (newBussinessData.email || '') + "?subject=MW Thought Leadership"}>{newBussinessData.email || ''}</a></Email>
                        </ContactInfoBox>
                    </Col>
                    <Col xs={"12"} sm={"6"} md={"6"} lg={"6"}>
                        <ContactInfoBox>
                            <ContactTitle>MEDIA INQUIRIES</ContactTitle>
                            <ContactName>{mediaInquiries.name}</ContactName>
                            <PhoneNumber>{mediaInquiries.phone}</PhoneNumber>
                            <Email><a href={"mailto:" + (mediaInquiries.email || '') + "?subject=MW Thought Leadership"}>{mediaInquiries.email || ''}</a></Email>
                        </ContactInfoBox>
                    </Col>
                </ContactSlotRow>
            </Container>
        </ContactSection>
    )
}

export default Contact;