import React from 'react'

const LoadingIcon = props => (
  <svg
    width={200}
    height={200}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="prefix__lds-rolling"
    style={{
      background: '0 0',
    }}
    {...props}
  >
    <circle
      cx={50}
      cy={50}
      fill="none"
      stroke="#fdfdfd"
      strokeWidth={10}
      r={35}
      strokeDasharray="164.93361431346415 56.97787143782138"
      transform="rotate(215.919 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)

export default LoadingIcon;
